import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {observable, action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Input, Accordion, List} from 'semantic-ui-react';
import {omit, isEmpty, isFunction} from 'lodash';

import AsyncActionsModal from './AsyncActionsModal';
import {notify} from '../notifier';

import './ResourceDeletionModal.less';

@observer
export default class ResourceDeletionModal extends Component {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    batchDeletion: PropTypes.bool,
    resourceName: PropTypes.string,
    resourceNamePlural: PropTypes.string,
    resourceLabel: PropTypes.string,
    resourceLabels: PropTypes.arrayOf(PropTypes.string),
    onSuccess: PropTypes.func,
    notifyOnSuccess: PropTypes.bool,
    userConfirmation: PropTypes.bool,
    disableResubmission: PropTypes.bool,
    resetState: PropTypes.func,
  };

  static defaultProps = {
    batchDeletion: false,
    resourceName: 'Resource',
    resourceNamePlural: 'Resources',
    resourceLabels: [],
    notifyOnSuccess: true,
    userConfirmation: false,
    disableResubmission: false
  };

  @observable userEnteredResourceLabel = '';
  @observable submitted = false;

  @action
  setUserEnteredResourceLabel = (value) => {
    this.userEnteredResourceLabel = value;
  };

  @action
  resetState = () => {
    this.setUserEnteredResourceLabel('');
    this.submitted = false;
  };

  onMount = () => {
    this.resetState();
    if (this.props.resetState) this.props.resetState();
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get submitAvailable() {
    const {batchDeletion, userConfirmation, resourceLabel} = this.props;
    return (
      batchDeletion || !userConfirmation || this.userEnteredResourceLabel === resourceLabel
    ) && (!this.submitted || !this.props.disableResubmission);
  }

  submit = async () => {
    const {submit, batchDeletion, resourceName, resourceNamePlural, onSuccess, notifyOnSuccess} = this.props;
    this.submitted = true;
    const result = await submit();
    if (onSuccess) onSuccess({result});
    if (notifyOnSuccess) {
      notify({message: batchDeletion ?
        `${resourceNamePlural} have been successfully deleted` : `${resourceName} has been successfully deleted`});
    }
  };

  renderHeader = () => {
    const {header, batchDeletion, resourceName, resourceNamePlural} = this.props;
    if (header) return header;
    return batchDeletion ?
      `Delete these ${resourceNamePlural ?? 'resources'}?` : `Delete this ${resourceName ?? 'resource'}?`;
  };

  renderContent = () => {
    const {
      content, batchDeletion, userConfirmation, resourceName, resourceNamePlural, resourceLabel, resourceLabels,
      children
    } = this.props;
    const {userEnteredResourceLabel} = this;
    const childrenProps = {userEnteredResourceLabel};
    const message = content || [];
    if (isEmpty(message)) {
      if (resourceName) {
        if (batchDeletion) {
          message.push(resourceLabels.length ? `${resourceLabels.length} ` : 'These ', resourceNamePlural);
        } else if (resourceLabel) {
          message.push(resourceName);
        } else {
          message.push('This ', resourceName);
        }
      }
      if (!batchDeletion && resourceLabel) {
        message.push(' ', <strong key='resource-label'>{resourceLabel}</strong>);
      }
      message.push(' will be deleted from the system.');
    }
    return (
      <Fragment>
        <div>{message}</div>
        {batchDeletion && resourceLabels.length > 0 &&
          <Accordion
            panels={[{
              key: 'resource-label-list',
              title: `${resourceName} list (${resourceLabels.length})`,
              content: {content: <List items={resourceLabels} />}
            }]}
          />
        }
        {children ? isFunction(children) ? children(childrenProps) : React.cloneElement(children, childrenProps) : null}
        {!batchDeletion && userConfirmation && (
          <Fragment>
            <div>
              {'Enter '}
              <strong>{resourceLabel}</strong>
              {' in the field below to proceed.'}
            </div>
            <Input
              fluid
              autoFocus
              onChange={(e) => this.setUserEnteredResourceLabel(e.target.value)}
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const {batchDeletion, resourceName, resourceNamePlural} = this.props;
    return (
      <AsyncActionsModal
        className='resource-deletion-modal'
        closeIcon
        onMount={this.onMount}
        {...omit(this.props, [
          'submit', 'batchDeletion', 'resourceName', 'resourceNamePlural', 'resourceLabel', 'resourceLabels',
          'onSuccess', 'notifyOnSuccess', 'userConfirmation', 'resetState', 'children', 'disableResubmission'
        ])}
        header={this.renderHeader()}
        content={this.renderContent}
        actions={[
          {
            key: 'delete',
            content: 'Delete ' + (batchDeletion ? resourceNamePlural : resourceName),
            color: 'red',
            icon: 'trash',
            labelPosition: 'left',
            disabled: !this.submitAvailable,
            onClick: this.submit,
          },
        ]}
      />
    );
  }
}
