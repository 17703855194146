import {Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

Popup.propTypes = {
  ...Popup.propTypes,
  inverted: PropTypes.bool,
};

Popup.defaultProps = {
  ...Popup.defaultProps,
  inverted: true,
};
