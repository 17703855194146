import {forEach, includes, map} from 'lodash';
import {Range} from 'ace-builds';

// Ace' service classnames to highlight lines
const aceHighlightingClasses = [
  'ace_active-line', 'ace_selected-word', 'ace_selection', 'ace_diff-added', 'ace_diff-removed', 'ace_diff-empty'
];

const setSessionErrors = (session, validationErrors) => {
  // Annotations (red circles with tooltips in gutter) and markers (underlined
  // inline errors) are built from the single source - validationErrors parameter.

  // Current session can contain custom errors (externally submitted).
  const errorsCombined = [
    ...validationErrors, ...(session?.errors ?? [])
  ];

  // Annotations have different structure (row, text, type, column)
  session.setAnnotations(
    map(
      errorsCombined,
      ({text, type, startRow, startCol, source}) => ({text, type, row: startRow, column: startCol, source})
    )
  );

  // Markers contain more details to mark a piece of text
  const markers = map(
    errorsCombined,
    ({text, type, startRow, endRow, startCol, endCol, source}) => ({
      className: `ace_validation-${type}`,
      type: 'background',
      text, startRow, endRow, startCol, endCol, source
    })
  );

  // Removing all markers currently in front
  let currentMarkers = session.getMarkers(true);
  forEach(currentMarkers, ({id}) => session.removeMarker(id));

  // Non-service markers (diff/additions/removals/current line) from the back must also be removed
  currentMarkers = session.getMarkers(false);
  forEach(currentMarkers, ({clazz, id}) => {
    if (!includes(aceHighlightingClasses, clazz)) {
      session.removeMarker(id);
    }
  });

  // Adding the new markers to the back
  forEach(markers,
    ({startRow, startCol, endRow, endCol, className, type}) => {
      const range = new Range(startRow, startCol, endRow, endCol);
      session.addMarker(range, className, type, false);
    }
  );
};

export default setSessionErrors;
