import {isValidElement, useRef} from 'react';
import {Checkbox as SUIRCheckbox} from 'semantic-ui-react'; // eslint-disable-line no-restricted-imports
import {isString, uniqueId, compact} from 'lodash';

const Checkbox = ({onChange, label, 'aria-labelledby': ariaLabelledBy, ...props}) => {
  const labelId = useRef(null);
  if (!labelId.current) labelId.current = 'label' + uniqueId();

  const extraProps = {label};
  const labelIds = [ariaLabelledBy];
  if (
    isString(label) && label.length ||
    isValidElement(label)
  ) {
    extraProps.label = <label id={labelId.current}>{label}</label>;
    labelIds.push(labelId.current);
  }
  const stringifiedLabelIds = compact(labelIds).join(' ');
  if (stringifiedLabelIds) extraProps['aria-labelledby'] = stringifiedLabelIds;
  if (onChange) extraProps.onChange = (e, data) => onChange(data);

  return <SUIRCheckbox {...props} {...extraProps} />;
};

export default Checkbox;
