import {map, isArray, isPlainObject} from 'lodash';

export default function generateOptionsFromSchema(schema = {}) {
  if (isArray(schema.enum)) {
    return map(schema.enum, (item) => ({key: item, value: item, text: item}));
  } else if (isArray(schema.oneOf) || isArray(schema.anyOf)) {
    const options = [];
    for (const item of (schema.oneOf || schema.anyOf)) {
      if (isPlainObject(item) && 'const' in item) {
        options.push({key: item.const, value: item.const, text: item.title || item.const});
      } else {
        return null;
      }
    }
    return options;
  }
  return null;
}
