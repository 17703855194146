import {Icon} from 'semantic-ui-react';
import {isString, startsWith} from 'lodash';
import PropTypes from 'prop-types';

import {brandColorNames} from '../brandColorNames';

const originalNamePropType = Icon.propTypes.name;
Icon.propTypes.name = function(props, propName, componentName) {
  if (isString(props.name) && startsWith(props.name, 'apstra-')) return;
  return originalNamePropType(props, propName, componentName);
};

Icon.propTypes.color = PropTypes.oneOf(brandColorNames);
