import React from 'react';
import {Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {isFunction} from 'lodash';

const PermissionChecker = ({
  children,
  hasPermissions = true,
  notPermittedMessage = 'Not enough permissions',
  wrapperClassName = '',
  popupProps,
  ...childrenProps
}) => {
  if ((React.isValidElement(children) && React.Children.count(children) === 1) || isFunction(children)) {
    const updatedProps = {
      ...(!isFunction(children) && children.props),
      ...childrenProps, // props from parent component (e.g. children is a trigger in Modal)
    };

    if (!hasPermissions) {
      updatedProps.disabled = !hasPermissions;
    }
    const updatedChildren = isFunction(children)
      ? children(updatedProps)
      : React.cloneElement(children, updatedProps);

    // <span/> wrapper used as a workaround for existing issue about non-working popup with a disabled
    // trigger component in some cases (https://github.com/Semantic-Org/Semantic-UI-React/issues/2804),
    // use wrapperClassName property to fix subsequent styling issues if they appear
    return (
      !hasPermissions
        ? (
          <Popup
            trigger={
              <span className={wrapperClassName}>
                {updatedChildren}
              </span>
            }
            content={notPermittedMessage}
            {...popupProps}
          />
        )
        : updatedChildren
    );
  } else {
    return children;
  }
};

PermissionChecker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  hasPermissions: PropTypes.bool,
  notPermittedMessage: PropTypes.string,
  wrapperClassName: PropTypes.string,
  popupProps: PropTypes.object,
};

export default PermissionChecker;
