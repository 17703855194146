import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {transform, get, isEqual, isEmpty, isFunction, merge} from 'lodash';
import {Base64} from 'js-base64';

import {withRouter} from '../withRouter';
import {DEFAULT_PAGE_SIZE} from './Pagination';

export function parseState(state) {
  return JSON.parse(Base64.decode(state));
}

export function stringifyState(state) {
  return Base64.encodeURI(JSON.stringify(state));
}

@observer
export default class DataFilteringContainer extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    defaultActivePage: PropTypes.number.isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    defaultSelection: PropTypes.object.isRequired,
    serializationSchema: PropTypes.object.isRequired,
    pushToHistory: PropTypes.bool,
  };

  static defaultProps = {
    defaultActivePage: 1,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    defaultFilters: {},
    defaultSorting: {},
    defaultSelection: {},
    serializationSchema: {
      activePage: 'defaultActivePage',
      pageSize: 'defaultPageSize',
      filters: 'defaultFilters',
      sorting: 'defaultSorting',
    }
  };

  @observable activePage = this.props.defaultActivePage;
  @observable pageSize = this.props.defaultPageSize;
  @observable defaultFilters = this.props.defaultFilters;
  @observable.ref filters = this.props.defaultFilters;
  @observable.ref sorting = this.props.defaultSorting;
  @observable.ref selection = this.props.defaultSelection;
  search = '';

  @action
  updateSelection = (selection) => {
    if (!isEqual(this.selection, selection)) {
      this.selection = selection;
    }
  };

  @action
  updatePagination = ({activePage, pageSize}) => {
    this.activePage = activePage;
    this.pageSize = pageSize;

    const {setUserStoreProps} = this.props;
    if (setUserStoreProps) setUserStoreProps({pageSize});
  };

  @action
  updateFilters = (filters, {resetActivePage = true, resetSelection = true} = {}) => {
    if (!isEqual(this.filters, filters)) {
      this.filters = filters;
      this.activePage = resetActivePage ? this.props.defaultActivePage : this.activePage;
      this.selection = resetSelection ? this.props.defaultSelection : this.selection;
    }
  };

  @action
  updateSorting = (sorting) => {
    if (!isEqual(this.sorting, sorting)) {
      this.sorting = sorting;
    }
  };

  @action
  updateStateFromLocation = (search) => {
    const {stateQueryParam} = this.props;
    if (stateQueryParam) {
      if (search !== this.search) {
        this.search = search;
        const searchParams = new URLSearchParams(search);
        const deserializedState = this.deserializeState(searchParams.get(stateQueryParam));
        const {activePage, pageSize, filters, sorting} = deserializedState;
        this.updateFilters(merge({}, this.filters, filters));
        this.updatePagination({activePage, pageSize});
        this.updateSorting(sorting);
      }
    }
  };

  updateLocationFromState = (serializedState) => {
    const {stateQueryParam} = this.props;
    if (stateQueryParam) {
      const searchParams = new URLSearchParams(this.props.location.search);
      if (serializedState) {
        searchParams.set(stateQueryParam, serializedState);
      } else {
        searchParams.delete(stateQueryParam);
      }
      const searchParamsAsString = searchParams.toString();
      const search = searchParamsAsString ? '?' + searchParamsAsString : '';
      if (search !== this.search) {
        this.search = search;
        this.replaceSearchInLocation(search);
      }
    }
  };

  replaceSearchInLocation(search) {
    if (this.replaceSearchTimeout) clearTimeout(this.replaceSearchTimeout);
    this.replaceSearchTimeout = setTimeout(
      () => this.props.navigate({search}, {replace: !this.props.pushToHistory}),
      0
    );
  }

  serializeState() {
    const serializedState = transform(this.props.serializationSchema, (result, defaultPropName, name) => {
      const value = this[name];
      if (!isEqual(value, this.props[defaultPropName])) {
        result[name] = value;
      }
    }, {});
    if (!isEmpty(serializedState)) {
      return stringifyState(serializedState);
    } else {
      return '';
    }
  }

  deserializeState(stringifiedSerializedState) {
    let serializedState = null;
    try {
      serializedState = parseState(stringifiedSerializedState);
    } catch (e) {}
    return transform(this.props.serializationSchema, (result, defaultPropName, name) => {
      const value = get(serializedState, [name], null);
      const defaultValue = this.props[defaultPropName];
      result[name] = value !== null && typeof value === typeof defaultValue ? value : defaultValue;
    }, {});
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeStateUpdater = reaction(
      () => (this.props.stateQueryParam && this.props.location) ? this.props.location.search : '',
      (search) => this.updateStateFromLocation(search),
      {fireImmediately: true}
    );
    this.disposeLocationUpdater = reaction(
      () => this.serializeState(),
      (serializedState) => this.updateLocationFromState(serializedState),
    );
    this.disposeDefaultFiltersUpdater = reaction(
      () => !isEqual(this.defaultFilters, this.props.defaultFilters),
      () => {
        this.updateFilters(this.props.defaultFilters);
        this.defaultFilters = this.props.defaultFilters;
      },
    );
  }

  componentWillUnmount() {
    this.disposeStateUpdater();
    this.disposeLocationUpdater();
    this.disposeDefaultFiltersUpdater();
  }

  render() {
    const {
      activePage, pageSize, filters, sorting,
      updatePagination, updateFilters, updateSorting,
      selection, updateSelection,
      props: {children}
    } = this;
    const props = {
      activePage, pageSize, filters, sorting,
      updatePagination, updateFilters, updateSorting,
      selection, updateSelection
    };
    return isFunction(children) ? children(props) : React.cloneElement(children, props);
  }
}

export const DataFilteringContainerWithRouter = withRouter(DataFilteringContainer);
