import {observable, action, makeObservable} from 'mobx';
import {useState, useEffect} from 'react';

export default class BaseStore {
  @observable props = {};
  disposers = [];

  constructor(props) {
    if (props) {
      this.registerProps(props);
    }
    makeObservable(this);
  }

  @action
  registerProps = (props) => {
    this.props = props;
  };

  shouldDispose(subscription) {
    this.disposers.push(subscription);
  }

  dispose() {
    this.disposers.forEach((dispose) => dispose());
  }
}

export const useStore = (StoreClass, props) => {
  const [store] = useState(() => {
    const store = new StoreClass(props);
    makeObservable(store);
    return store;
  });
  useEffect(() => store.registerProps(props), [store, props]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => store.dispose(), []);
  return store;
};
