import {Component} from 'react';
import {Loader as SemanticUILoader} from 'semantic-ui-react';

export default class Loader extends Component {
  static defaultProps = {
    size: 'large',
    text: 'Fetching data...',
  };
  render() {
    const {size, text} = this.props;
    return <SemanticUILoader active inline='centered' size={size}>{text}</SemanticUILoader>;
  }
}
