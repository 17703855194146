import {noop} from 'lodash';
import React, {PropsWithChildren} from 'react';

import {MainSidebarSize} from './types';

type StateContextType = {
  closeMenu: () => void;
  height?: number;
  openMenu: (menuKey: string) => void;
  openMenuKey?: string;
  setSize: (size: MainSidebarSize) => void;
  size: MainSidebarSize;
}

const StateContext = React.createContext<StateContextType>({
  closeMenu: noop,
  openMenu: noop,
  setSize: noop,
  size: 'expanded',
});

export const useStateContext = (): StateContextType => React.useContext(StateContext);

export const StateContextProvider: React.FC<PropsWithChildren<StateContextType>> = ({
  children,
  ...state
}) => {
  return <StateContext.Provider children={children} value={state} />;
};
