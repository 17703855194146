import {isFunction, isUndefined} from 'lodash';

export function createValueRenderer({condition, renderValue, renderValueInput, getQueryPartData}) {
  return {
    renderValue(params) {
      return condition(params) ? renderValue.call(this, params) : null;
    },
    renderValueInput(params) {
      return condition(params) ? renderValueInput.call(this, params) : null;
    },
    getQueryPartData(params) {
      return condition(params) ?
        (isFunction(getQueryPartData) ?
          getQueryPartData.call(this, params) :
          {value: renderValue.call(this, params), matcher: isUndefined(getQueryPartData) ? '=' : getQueryPartData}
        ) :
        null;
    },
    renderValueWithoutCondition: renderValue,
    renderValueInputWithoutCondition: renderValueInput,
  };
}

export function updateValueRendererCondition({condition, valueRenderer}) {
  return createValueRenderer({
    condition,
    renderValue: valueRenderer.renderValueWithoutCondition,
    renderValueInput: valueRenderer.renderValueInputWithoutCondition,
  });
}

export default createValueRenderer;
