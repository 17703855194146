import cx from 'classnames';
import {map} from 'lodash';
import {FC, useState} from 'react';
import {Icon, List, ListItem, ListList} from 'semantic-ui-react';

import {useGlobalHelp} from './GlobalHelpContext';
import {HelpSectionType, isHelpPageType} from './types';

import './GlobalTopicsTree.less';

type SectionProps = {
  topic: HelpSectionType
}

const Section: FC<SectionProps> = ({topic}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {navigate} = useGlobalHelp();
  return (
    <ListItem key={topic.title}>
      <div
        className='topics-tree-section'
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        onKeyDown={() => {}}
        role='button'
        tabIndex={0}
      >
        <Icon name={isExpanded ? 'chevron down' : 'chevron right'} />
        {topic.title}
      </div>
      {isExpanded &&
        <ListList>
          {map(topic.children, (item) => (
        isHelpPageType(item) ?
          <ListItem
            key={item.title}
            className='topics-tree-item'
            content={item.title}
            icon='genderless'
            onClick={() => navigate(item.url)}
          /> :
          <Section key={item.title} topic={item} />
          ))}
        </ListList>
      }
    </ListItem>
  );
};

export const GlobalTopicsTree: React.FC = () => {
  const {topics, url} = useGlobalHelp();
  return (
    <List className={cx('topics-tree', {hidden: !!url})}>
      {map(topics, (topic) => (
        <Section
          key={topic.title}
          topic={topic}
        />
      ))}
    </List>
  );
};
