import {Fragment, createElement} from 'react';
import {arrayOf, string, bool, object, func, number, oneOfType} from 'prop-types';
import {PropTypes as MobXPropTypes, observer} from 'mobx-react';
import {assign, clone, cloneDeep, get, isArray, isEmpty, isNull, map} from 'lodash';
import cx from 'classnames';

import ValueInput from '../ValueInput';
import Field from '../Field';
import FormFragmentsArray from './FormFragmentsArray';
import SchemalessInput from './SchemalessInput';

import './RichFormFragment.less';

export const OWN_ERRORS_KEY = '_';

const RichFormFragment = (props) => {
  const {
    schema: topSchema = [],
    values = {},
    errors = {},
    disabled: formFragmentDisabled,
    hidden: formFragmentHidden,
    path = [],
    noSchema, // Set to TRUE if given set of values might only be updated or deleted (outside of the schema)
    onChange,
    labelButtons,
    formState,
    setFormState,
    ...formFragmentProps
  } = props;

  if (formFragmentHidden) return null;
  const ownErrors = errors?.[OWN_ERRORS_KEY];

  return (
    <Fragment>
      {
        map(topSchema, (item, index) => {
          const {name, schema, required, disabled, triggers, hidden, as, fieldProps, ...valueInputProps} = item;
          const {disabled: stateDisabled, hidden: stateHidden} = get(formState, [...path, name], {});
          if (stateHidden) return null;

          const combinedDisabled = disabled || formFragmentDisabled || stateDisabled;

          const value = clone(values?.[name] ?? (schema?.type === 'array' ? [] : {}));

          const itemErrors = errors?.[name];
          const title = (schema?.title || name);

          // If label buttons supplied only show them on the first item
          const label = !index && labelButtons && !noSchema ?
            <Fragment key={name}>
              {labelButtons}
              {title}
            </Fragment> :
            title;

          if (isArray(schema?.itemSchema)) {
            if (schema?.type === 'array') {
              // Array of schemed fragments
              return (
                <FormFragmentsArray
                  key={name}
                  name={name}
                  schema={schema}
                  values={value}
                  errors={itemErrors}
                  onChange={onChange}
                  path={[...path, name]}
                  formState={formState}
                  setFormState={setFormState}
                  disabled={combinedDisabled}
                  required={required}
                  fieldProps={fieldProps}
                  noSchema={noSchema}
                />);
            } else {
              const children = (
                <div>
                  <RichFormFragment
                    schema={schema.itemSchema}
                    values={value}
                    onChange={onChange}
                    path={[...path, name]}
                    errors={itemErrors}
                    formState={formState}
                    setFormState={setFormState}
                    disabled={combinedDisabled}
                    hidden={hidden}
                    noSchema={noSchema}
                  />
                </div>
              );
              const className = cx(
                'rich-form-fragment',
                name,
                {
                  'contains-errors': !isEmpty(itemErrors) || !isEmpty(ownErrors),
                  'no-schema': noSchema
                }
              );
              if (as) {
                const props = {
                  key: name,
                  ...item,
                  className,
                  values: value,
                  errors: itemErrors,
                  onChange,
                  path: [...path, name],
                  formState: formState,
                  setFormState,
                  disabled: combinedDisabled
                };
                return createElement(as, props, children);
              } else {
                return (
                  <div key={name} className={className}>
                    <Field
                      description={schema?.description}
                      label={label}
                      required={required}
                      errors={ownErrors}
                      {...fieldProps}
                    />
                    {children}
                  </div>
                );
              }
            }
          } else if (!schema) {
            return <SchemalessInput
              key='empty'
              levelSchema={topSchema}
              path={path}
              values={cloneDeep(values)}
              required={required}
              errors={itemErrors}
              onChange={onChange}
              formState={formState}
              setFormState={setFormState}
            />;
          } else {
            // Value cannot be null for controlled components, thus making sure that empty string is supplied
            // for null values
            const valuesValue = values?.[name];
            const value = (isNull(valuesValue) && !as) ? '' : valuesValue;
            const itemErrors = errors?.[name];
            const valueErrors = itemErrors ? (isArray(itemErrors) ? itemErrors : [itemErrors]) : undefined;
            const className = cx({'no-schema': noSchema}, name, schema?.type);
            return <ValueInput
              key={name}
              as={as}
              name={name}
              value={value}
              values={values}
              schema={schema}
              required={required}
              disabled={combinedDisabled}
              errors={valueErrors}
              onChange={(value) => onChange([...path, name], value, triggers)}
              {...formFragmentProps}
              {...valueInputProps}
              fieldProps={assign({}, fieldProps, {label, className})}
            />;
          }
        })
      }
    </Fragment>
  );
};

RichFormFragment.propTypes = {
  schema: arrayOf(object).isRequired,
  errors: oneOfType([MobXPropTypes.objectOrObservableObject, string]),
  disabled: bool,
  onChange: func,
  path: arrayOf(oneOfType([string, number]))
};

export default observer(RichFormFragment);
