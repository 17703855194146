import {useRef} from 'react';
import PropTypes from 'prop-types';
import {DateTimeInput} from 'semantic-ui-calendar-react-yz';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import moment from 'moment';

import parseTimestamp from '../parseTimestamp';
import BaseStore, {useStore} from '../BaseStore';

import './CalendarControl.less';

const propTypeDateOrString = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]);

class Store extends BaseStore {
  @computed get value() {
    const {value, dateTimeFormat} = this.props;
    return value ? moment(parseTimestamp(value)).format(dateTimeFormat) : null;
  }

  @computed get minDate() {
    return parseTimestamp(this.props.minDate);
  }

  @computed get maxDate() {
    return parseTimestamp(this.props.maxDate);
  }
}

const serializeDate = (date) => date ? moment.utc(date).format() : null;

const CalendarControl = observer((props) => {
  const containerRef = useRef();
  const store = useStore(Store, props);

  const {dateTimeFormat, disabled, placeholder, clearable, serializeDates} = props;
  const {value, minDate, maxDate} = store;

  const onChange = (value) => {
    if (value) value = moment(value, dateTimeFormat, true).toDate();
    props.onChange?.(value ? serializeDates ? serializeDate(value) : value : null);
  };

  return (
    <div ref={containerRef} className='calendar-control'>
      <DateTimeInput
        mountNode={containerRef.current}
        clearable={clearable}
        closable={false}
        value={value ?? ''}
        onChange={(e, {value}) => onChange(value)}
        onClear={() => onChange(null)}
        minDate={minDate}
        maxDate={maxDate}
        dateTimeFormat={dateTimeFormat}
        placeholder={placeholder}
        disabled={disabled}
        animation={null}
        duration={0}
        closeOnMouseLeave={false}
      />
    </div>
  );
});

CalendarControl.propTypes = {
  value: propTypeDateOrString,
  onChange: PropTypes.func.isRequired,
  minDate: propTypeDateOrString,
  maxDate: propTypeDateOrString,
  serializeDates: PropTypes.bool,
  dateTimeFormat: PropTypes.string,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};

CalendarControl.defaultProps = {
  value: null,
  minDate: null,
  maxDate: null,
  serializeDates: true,
  dateTimeFormat: 'MMM D, YYYY HH:mm',
  placeholder: 'Choose date...',
  clearable: true,
};

export default CalendarControl;
