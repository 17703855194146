import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'semantic-ui-react';
import {observable, action, computed, runInAction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isFunction, isUndefined, forEachRight} from 'lodash';
import isPromise from 'is-promise';

import FetchDataError from './FetchDataError';

@observer
export default class AsyncActionsModal extends Component {
  static propTypes = {
    ...Modal.propTypes,
    actions: PropTypes.array.isRequired,
    renderError: PropTypes.func,
  };

  @observable isModalOpen = false;
  @observable currentActionKey = null;
  @observable.ref actionError = null;

  @action
  openModal = () => {
    this.isModalOpen = true;
  };

  @action
  closeModal = () => {
    this.isModalOpen = false;
  };

  @action
  onMount = () => {
    this.currentActionKey = null;
    this.actionError = null;
    if (this.props.onMount) this.props.onMount();
  };

  @action
  onClose = () => {
    if (!this.currentActionKey) {
      this.closeModal();
      if (this.props.onClose) this.props.onClose();
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get actions() {
    return this.props.actions.map(({...action}) => {
      if (isFunction(action.onClick)) {
        action.onClick = this.wrapOnClick(action.onClick, action.key);
      } else {
        action.onClick = this.onClose;
      }
      if (this.currentActionKey) {
        action.disabled = true;
      }
      if (action.key === this.currentActionKey) {
        action.loading = true;
      }
      return action;
    });
  }

  wrapOnClick = (onClick, key) => {
    return action(async (...args) => {
      const result = onClick(...args);
      if (isPromise(result)) {
        this.currentActionKey = key;
        let actionError = null;
        try {
          await result;
        } catch (error) {
          actionError = error;
        }
        runInAction(() => {
          this.currentActionKey = null;
          this.actionError = actionError;
          if (!actionError) {
            this.onClose();
          }
        });
      }
    });
  };

  submitOnEnter = (e) => {
    if (e.key === 'Enter') this.submit();
  };

  submit = () => {
    forEachRight(this.actions, (action) => {
      if (!action.disabled) {
        action.onClick();
        return false;
      }
    });
  };

  render() {
    const {open, trigger, closeIcon, header, content, errorTitlesByStatus, renderError, ...modalProps} = this.props;
    delete modalProps.actions;
    delete modalProps.children;
    const isControlled = !isUndefined(open);
    const {currentActionKey, actionError} = this;
    const contentProps = {currentActionKey, actionError};
    return (
      <Modal
        trigger={isControlled ? trigger : React.cloneElement(trigger, {onClick: this.openModal})}
        open={isControlled ? open : this.isModalOpen}
        closeIcon={currentActionKey ? false : closeIcon}
        onKeyDown={this.submitOnEnter}
        {...modalProps}
        onMount={this.onMount}
        onClose={this.onClose}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          {
            actionError ? renderError ?
              renderError(actionError, {titlesByStatus: errorTitlesByStatus})
            :
              <FetchDataError
                error={actionError}
                titlesByStatus={errorTitlesByStatus}
              />
            :
              isFunction(content) ? content(contentProps) : React.cloneElement(content, contentProps)
          }
        </Modal.Content>
        <Modal.Actions>
          {this.actions.map((props, index) => <Button key={index} {...props} />)}
        </Modal.Actions>
      </Modal>
    );
  }
}
