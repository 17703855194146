import PropTypes from 'prop-types';

import AsyncActionsModal from './AsyncActionsModal';

const ConfirmModal = ({onConfirm, ...props}) => {
  return (
    <AsyncActionsModal
      closeIcon
      actions={[
        {
          key: 'cancel',
          negative: true,
          content: 'Cancel'
        },
        {
          key: 'confirm',
          positive: true,
          content: 'Confirm',
          icon: 'checkmark',
          labelPosition: 'right',
          onClick: onConfirm
        },
      ]}
      {...props}
    />
  );
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
