import {isNumber, max, transform} from 'lodash';
import {useCallback, useState} from 'react';

export const createAxisMeasurer = (handler) => {
  return (node) => {
    const width = max(
      transform(
        node?.children,
        (acc, child) => {
          if (child?.tagName === 'g') acc.push(child?.getBBox?.()?.width);
        },
        []
      )
    );
    handler?.(width);
  };
};

const useAxisWidth = (label, units) => {
  const [maxLabelWidth, updateMaxLabelWidth] = useState(0);

  const registerChanges = useCallback((width) => {
    if (isNumber(width) && width !== maxLabelWidth) updateMaxLabelWidth(width);
  }, [maxLabelWidth]);

  const ref = createAxisMeasurer(registerChanges);

  return {ref, width: maxLabelWidth + ((label || units) ? 15 : 0), maxLabelWidth};
};

export default useAxisWidth;
