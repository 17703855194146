import cx from 'classnames';
import {filter, isString, map, some, sortBy} from 'lodash';
import React from 'react';
import {Icon} from 'semantic-ui-react';

import {onEnterKeyHandler} from '../../keyHandlers';
import {RegisteredButton, useMainSidebar} from './MainSidebarProvider';
import {PopupMenu} from './PopupMenu';
import {useStateContext} from './StateContext';
import {SubmenuContent} from './SubmenuContent';
import {useTrackOutsideClick} from './useTrackOutsideClick';

import './BottomPanel.less';

type ButtonProps = React.PropsWithChildren<{
  ariaLabel: string;
  className?: string;
  onClick?: () => void;
}>;

const Button: React.FC<ButtonProps> = ({ariaLabel, children, className, onClick}) => {
  return (
    <div
      className={cx('button', className)}
      onClick={onClick}
      onKeyDown={onClick && onEnterKeyHandler(onClick)}
      role='menu'
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {children}
    </div>
  );
};

type DropdownButtonProps = {
  button: RegisteredButton;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({button}) => {
  const {openMenuKey, openMenu, closeMenu} = useStateContext();
  const {menuItems} = useMainSidebar();
  const ref = React.useRef<HTMLDivElement>(null);
  const menuKey = button.button.menuKey || button.id;
  const isOpen = menuKey === openMenuKey;
  const buttonMenu = React.useMemo(
    () => button.button.isMenu ? sortBy(filter(menuItems, {parent: button.button.menuKey}), 'order') : [],
    [button.button.isMenu, button.button.menuKey, menuItems]
  );
  useTrackOutsideClick({
    enabled: isOpen,
    onOutsideClick: closeMenu,
    ref
  });
  return (
    <div
      ref={ref}
      className={cx('dropdown-button', {right: button.button.right})}
    >
      <>
        <Button
          ariaLabel={button.button['aria-label']}
          onClick={() => isOpen ? closeMenu() : openMenu(menuKey)}
        >
          {isString(button.button.icon) && <Icon className={button.button.icon} />}
          {React.isValidElement(button.button.icon) && button.button.icon}
          {button.button.label}
        </Button>
        {isOpen &&
          <PopupMenu>
            {some(buttonMenu) ? <SubmenuContent menuItems={buttonMenu} /> : <>{button.button.children}</>}
          </PopupMenu>
        }
      </>
    </div>
  );
};

export const BottomPanel = () => {
  const {buttons} = useMainSidebar();
  const {setSize, size} = useStateContext();
  const isExpanded = size === 'expanded';
  const orderedButtons = sortBy(buttons, 'right');
  return (
    <div className={cx('bottom-panel', {expanded: isExpanded})}>
      {map(orderedButtons, (button) => {
        return (
          (button.button.children || button.button.isMenu) ?
            <DropdownButton
              key={button.id}
              button={button}
            />
            :
            <Button
              key={button.id}
              ariaLabel={button.button['aria-label']}
              className={cx({right: button.button.right})}
            >
              {button.button.label}
            </Button>
        );
      })}
      <Button
        ariaLabel={isExpanded ? 'collapse menu' : 'expand menu'}
        className='expand right'
        onClick={() => setSize(isExpanded ? 'default' : 'expanded')}
      >
        <Icon className={`apstra-sidebar-icon apstra-sidebar-${isExpanded ? 'collapse' : 'expand'}-arrow`} />
      </Button>
    </div>
  );
};
