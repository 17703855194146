import {Component} from 'react';
import {Table, Icon, Button, Progress, Popup} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import {noop, isNil} from 'lodash';
import cx from 'classnames';

import Field from './Field';
import formatNumber from '../formatters/formatNumber';

import './SingleFileInput.less';

export default class SingleFileInput extends Component {
  static defaultProps = {
    height: 70,
    description: 'Drag and drop file here or choose file by clicking the button.',
  };

  render() {
    const {value: file, schema = {}, required, disabled, onChange, height,
      description, accept, uploadProgress, cancellationAvailable = false} = this.props;
    const formatBytesNumber = (size) => formatNumber(size, {units: 'B', short: true});
    return (
      <Field
        className='single-file-input'
        label={schema.title}
        description={schema.description}
        required={required}
      >
        {file ?
          <Table basic='very' style={{height}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Icon name='file' />
                  {file.name}
                </Table.Cell>
                <Table.Cell>
                  {formatBytesNumber(file.size)}
                </Table.Cell>
                <Table.Cell width={4}>
                  {!isNil(uploadProgress) && (
                    <Popup
                      content={
                        `${formatBytesNumber(uploadProgress.loaded)} / ${formatBytesNumber(uploadProgress.total)}`
                      }
                      trigger={
                        <Progress
                          size='small'
                          percent={uploadProgress.progress}
                          progress
                          autoSuccess
                          active
                        />
                      }
                    />
                  )}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  <Icon
                    link
                    disabled={disabled && !cancellationAvailable}
                    name='remove'
                    size='large'
                    color={disabled && !cancellationAvailable ? 'grey' : 'red'}
                    onClick={() => onChange(null)}
                    aria-label='Delete'
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        :
          <Dropzone
            multiple={false}
            accept={accept}
            disabled={disabled}
            noClick
            onDrop={([file]) => onChange(file)}
          >
            {({getRootProps, getInputProps, open, isDragAccept, isDragReject}) => (
              <div
                className={cx('dropzone', {'drag-accept': isDragAccept, 'drag-reject': isDragReject})}
                {...getRootProps({onClick: noop})}
              >
                <input {...getInputProps()} />
                <Table basic='very' style={{height}}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {description}
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Button
                          positive
                          icon='file'
                          content='Choose File'
                          disabled={disabled}
                          onClick={open}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            )}
          </Dropzone>
        }
      </Field>
    );
  }
}
