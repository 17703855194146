import cx from 'classnames';
import React from 'react';

import './Hoverable.less';

type HoverableProps = React.PropsWithChildren<{
  highlighted?: boolean;
}>;

export const Hoverable: React.FC<HoverableProps> = ({
  children, highlighted
}) => {
  return (
    <div
      children={children}
      className={cx('main-sidebar-hoverable', {highlighted})}
    />
  );
};
