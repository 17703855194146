import {Component, cloneElement, Children, isValidElement} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {Form, Popup, Icon} from 'semantic-ui-react';
import {isEmpty, isFunction, compact, uniqueId, toString} from 'lodash';

import ValidationErrors from './ValidationErrors';

import './Field.less';

export const POSITION_ABOVE = 'above';
export const POSITION_BELOW = 'below';
export const POSITION_TOOLTIP = 'tooltip';

@observer
export default class Field extends Component {
  static propTypes = {
    ...Form.Field.propTypes,
    fieldId: PropTypes.string,
    label: PropTypes.node,
    description: PropTypes.node,
    descriptionPosition: PropTypes.oneOf([POSITION_ABOVE, POSITION_BELOW, POSITION_TOOLTIP]),
    errors: PropTypes.arrayOf(PropTypes.string),
    errorsPosition: PropTypes.oneOf([POSITION_ABOVE, POSITION_BELOW]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  };

  static defaultProps = {
    errors: [],
    errorsPosition: POSITION_BELOW,
    descriptionPosition: POSITION_BELOW,
  };

  @computed get fieldId() {
    return uniqueId();
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  breakIntoLines(text) {
    return toString(text).split('\n').map((line, index) => <p key={index}>{line}</p>);
  }

  render() {
    const {
      label, description, descriptionPosition, DescriptionComponent, disabled, errors, errorsPosition, children,
      fieldId: providedFieldId,
      ...fieldProps
    } = this.props;
    let renderedLabel, renderedDescription;
    const fieldId = providedFieldId ?? this.fieldId;
    const childrenProps = {disabled};
    const labelControls = compact([
      label,
      (description && descriptionPosition === POSITION_TOOLTIP) &&
        <Popup
          key='popup'
          trigger={<Icon name='info circle' aria-label={description} role='img' />}
          content={DescriptionComponent ?
            <DescriptionComponent description={description} /> :
            this.breakIntoLines(description)
          }
          position='right center'
          wide
        />
    ]);
    if (!isEmpty(labelControls)) {
      const labelId = 'label' + fieldId;
      renderedLabel = <label id={labelId}>{labelControls}</label>;
      childrenProps['aria-labelledby'] = labelId;
    }
    if ((descriptionPosition === POSITION_ABOVE || descriptionPosition === POSITION_BELOW) && description) {
      const descriptionId = 'description' + fieldId;
      renderedDescription = <div
        id={descriptionId}
        className='form-text'
        children={DescriptionComponent ? <DescriptionComponent description={description} /> : description}
      />;
      if (!disabled) childrenProps['aria-describedby'] = descriptionId;
    }
    const updatedChildren = isFunction(children) ? children(childrenProps) :
      (isValidElement(children) && Children.count(children) === 1) ? cloneElement(children, childrenProps) :
      children;
    return (
      <Form.Field
        {...fieldProps}
        disabled={disabled}
        error={!isEmpty(errors)}
      >
        {renderedLabel}
        {descriptionPosition === POSITION_ABOVE && renderedDescription}
        {errorsPosition === POSITION_ABOVE && <ValidationErrors errors={errors} pointing='below' />}
        {updatedChildren}
        {errorsPosition === POSITION_BELOW && <ValidationErrors errors={errors} pointing='above' />}
        {descriptionPosition === POSITION_BELOW && renderedDescription}
      </Form.Field>
    );
  }
}
