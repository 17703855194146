import {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, computed, observe, reaction, comparer, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {forEach, castArray, values, flatten, isEmpty, isPlainObject, keys} from 'lodash';
import cx from 'classnames';

import Field from './Field';
import MultiItemsControl from './MultiItemsControl';

@observer
export default class MapInput extends Component {
  static propTypes = {
    maxItems: PropTypes.number,
    minItems: PropTypes.number,
    generateNewEntry: PropTypes.func,
    fieldWidth: PropTypes.number,
    addButtonProps: PropTypes.object,
    deleteButtonProps: PropTypes.object,
  };

  static defaultProps = {
    errors: [],
    generateNewEntry: () => ['', ''],
    flattenErrors: true
  };

  @observable entries = [];

  @action
  setEntryKey = (index, key) => {
    const newEntries = [...this.entries];
    newEntries[index][0] = key;
    this.entries = newEntries;
  };

  @action
  setEntryValue = (index, value) => {
    const newEntries = [...this.entries];
    newEntries[index][1] = value;
    this.entries = newEntries;
  };

  @action
  addEntry = (entry = this.props.generateNewEntry()) => {
    this.entries = [...this.entries, entry];
  };

  @action
  removeEntry = ({index}) => {
    this.entries = this.entries.filter((entry, entryIndex) => index !== entryIndex);
  };

  @action
  removeAllEntries = () => {
    this.entries.length = 0;
  };

  @action
  updateEntriesFromProps = ({value: entries} = this.props) => {
    if (isEmpty(entries)) {
      // special case for removal all entries since it's tricky to remove single entries
      this.removeAllEntries();
    } else {
      forEach(this.entries, ([existingEntryKey]) => {
        const entryWithSameKeyIndex = keys(entries).findIndex((entryKey) => entryKey === existingEntryKey);
        if (entryWithSameKeyIndex === -1) {
          const existingEntryWithSameKeyIndex = this.entries.findIndex((entry) => entry[0] === existingEntryKey);
          this.removeEntry({index: existingEntryWithSameKeyIndex});
        }
      });
      forEach(entries, (value, key) => {
        const existingExactEntryIndex = this.entries.findIndex((entry) => entry[0] === key && entry[1] === value);
        if (existingExactEntryIndex === -1) {
          const existingEntryWithSameKeyIndex = this.entries.findIndex((entry) => entry[0] === key);
          if (existingEntryWithSameKeyIndex === -1) {
            this.addEntry([key, value]);
          } else {
            this.setEntryValue(existingEntryWithSameKeyIndex, value);
          }
        }
      });
    }
  };

  onEntriesChange = () => {
    this.props.onChange(this.entriesAsValue);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeEntriesUpdater = reaction(
      () => this.props.value,
      (value) => this.updateEntriesFromProps({value}),
      {equals: comparer.structural, fireImmediately: true}
    );
    this.disposeEntriesObserver = observe(this, 'entries', this.onEntriesChange);
  }

  componentWillUnmount() {
    this.disposeEntriesUpdater();
    this.disposeEntriesObserver();
  }

  @computed get entriesAsValue() {
    return this.entries.reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
  }

  @computed get errors() {
    return this.props.errors.reduce((result, error) => {
      if (isPlainObject(error)) {
        forEach(error, (entryError, key) => {
          const index = this.entries.findIndex((entry) => entry[0] === key);
          if (index !== -1) {
            if (!this.props.flattenErrors) {
              result.byIndex[index] = entryError;
            } else if (isPlainObject(entryError)) {
              result.byIndex[index] = flatten(values(entryError));
            } else {
              result.byIndex[index] = castArray(entryError);
            }
          }
        });
      } else {
        result.generic.push(...castArray(error));
      }
      return result;
    }, {byIndex: {}, generic: []});
  }

  render() {
    const {
      name, schema, required, disabled, className, buttonText,
      noItemsMessage, minItems, maxItems, fieldWidth,
      children, addButtonProps, deleteButtonProps,
      fieldProps
    } = this.props;
    const {entries, setEntryKey, setEntryValue} = this;
    return (
      <Field
        {...fieldProps}
        className={cx('multi-items-input', className)}
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={this.errors.generic}
        errorsPosition='above'
        width={fieldWidth}
      >
        {(inputProps) =>
          <MultiItemsControl
            items={entries}
            buttonText={buttonText}
            noItemsMessage={noItemsMessage}
            minItems={minItems}
            maxItems={maxItems}
            addItem={this.addEntry}
            removeItem={this.removeEntry}
            disabled={disabled}
            addButtonProps={addButtonProps}
            deleteButtonProps={deleteButtonProps}
          >
            {({item: [key, value], index}) => children({
              key, value, index, setEntryKey, setEntryValue, errors: this.errors.byIndex[index], ...inputProps
            })}
          </MultiItemsControl>
        }
      </Field>
    );
  }
}
