import {set} from 'lodash/fp';
import {concat, get, isEmpty, map, size} from 'lodash';
import {Button, Icon, Segment} from 'semantic-ui-react';
import {Fragment} from 'react';
import {arrayOf, string, bool, object, func, number, oneOfType, shape, oneOf} from 'prop-types';
import {PropTypes as MobXPropTypes, observer} from 'mobx-react';
import cx from 'classnames';

import Field from '../Field';
import ValidationErrors from '../ValidationErrors';
import ButtonLink from './ButtonLink';
import RichFormFragment, {OWN_ERRORS_KEY} from './RichFormFragment';
import {getSchemaDefaults} from '../../formSchema';

import './FormFragmentsArray.less';

const FormFragmentsArray = ({name, schema: {title, description, itemSchema}, values, onChange, path, errors, required,
  disabled, formState, setFormState, fieldProps = {}, noSchema}) => {
  const statePath = [...path, 'expanded'];
  const noItems = isEmpty(values);
  const expanded = get(formState, statePath, false) && !noItems;
  const setExpansion = (isExpanded) => setFormState?.(set(statePath, isExpanded, formState));

  const buttons = (
    <Fragment key={name}>
      <ButtonLink
        aria-label={expanded ? 'Collapse' : 'Expand'}
        className={cx({expanded})}
        disabled={noItems}
        onClick={() => setExpansion(!expanded)}
        content={
          <>
            <Icon name='dropdown' />
            <span>{`${title || name} (${size(values)})`}</span>
          </>
        }
      />
      {
        ((expanded || noItems) && !disabled && !noSchema) &&
          <Button
            aria-label='Add Item'
            icon='plus'
            size='tiny'
            floated='right'
            disabled={disabled}
            onClick={() => {
              setExpansion(true);
              onChange(path, concat(values ?? [], getSchemaDefaults(itemSchema)));
            }}
          />
      }
    </Fragment>
  );

  const ownErrors = errors?.[OWN_ERRORS_KEY];
  const classNames = cx(
    'form-fragments-array',
    name,
    expanded,
    {
      'contains-errors': !isEmpty(errors) || !isEmpty(ownErrors),
      'no-schema': noSchema
    }
  );
  const itemsSize = size(values);
  const showItemDeleteButton = !disabled && (!required || itemsSize > 1);

  return (
    <div className={classNames}>
      <Field
        label={buttons}
        description={description}
        required={required}
        errors={ownErrors}
        {...fieldProps}
      />
      {
        expanded &&
          <Segment>
            {
              map(values, (item, index) => {
                const itemErrors = errors?.[index]?.[OWN_ERRORS_KEY];
                return (
                  <div key={index} className={cx('item', `item${index}`)}>
                    {
                      itemErrors &&
                        <ValidationErrors errors={itemErrors} pointing={false} />
                    }

                    <RichFormFragment
                      schema={itemSchema}
                      values={item}
                      onChange={onChange}
                      path={[...path, index]}
                      errors={errors?.[index]}
                      disabled={disabled}
                      formState={formState}
                      setFormState={setFormState}
                      noSchema={noSchema}
                      labelButtons={showItemDeleteButton &&
                        <Button
                          aria-label='Remove Item'
                          icon='trash'
                          size='tiny'
                          floated='right'
                          onClick={() => {
                            if (itemsSize === 1) setExpansion(false);
                            onChange(path, values.toSpliced(index, 1));
                          }}
                        />
                      }
                    />
                  </div>
                );
              })
            }
          </Segment>
      }
    </div>
  );
};

FormFragmentsArray.propTypes = {
  schema: shape({
    type: oneOf(['array']),
    itemSchema: arrayOf(object)
  }),
  values: arrayOf(MobXPropTypes.objectOrObservableObject),
  disabled: bool,
  onChange: func,
  path: arrayOf(oneOfType([string, number]))
};

export default observer(FormFragmentsArray);
