import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes, observer} from 'mobx-react';
import {map} from 'lodash';

import ValueInput from './ValueInput';

@observer
export default class FormFragment extends Component {
  static propTypes = {
    schema: PropTypes.arrayOf(PropTypes.object).isRequired,
    values: MobXPropTypes.objectOrObservableObject.isRequired,
    errors: MobXPropTypes.objectOrObservableObject,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    schema: [],
    values: {},
    errors: {},
    disabled: false
  };

  render() {
    const {
      schema, values, errors,
      disabled: formFragmentDisabled,
      hidden: formFragmentHidden,
      onChange,
      ...formFragmentProps
    } = this.props;
    if (formFragmentHidden) return null;
    return (
      <Fragment>
        {map(schema, ({name, schema, required, disabled, hidden, as, ...valueInputProps}) =>
          !hidden &&
            <ValueInput
              key={name}
              as={as}
              name={name}
              value={values[name]}
              values={values}
              schema={schema}
              required={required}
              disabled={formFragmentDisabled ? formFragmentDisabled : disabled}
              errors={errors[name]}
              onChange={(value) => onChange(name, value)}
              {...formFragmentProps}
              {...valueInputProps}
            />
        )}
      </Fragment>
    );
  }
}
