import {createRoot} from 'react-dom/client';
import {once} from 'lodash';

import Notifier from './components/Notifier';

let notifierContainer = null;
function getNotifierContainer() {
  if (notifierContainer) return notifierContainer;
  notifierContainer = document.createElement('div');
  document.body.appendChild(notifierContainer);
  return notifierContainer;
}

let notifierRef = null;
function getNotifier() {
  notifierRef ??= new Promise((resolve) => {
    const root = createRoot(getNotifierContainer());
    root.render(<Notifier ref={once(resolve)} />);
  });
  return notifierRef;
}

export async function notify(params) {
  const notifier = await getNotifier();
  return notifier.showNotification(params);
}

export async function dismiss(notifcationId) {
  const notifier = await getNotifier();
  return notifier.hideNotification(notifcationId);
}

export function showError(error) {
  let title = 'Error';
  let content = '';
  if (error.toMessage) {
    ({title, content} = error.toMessage());
  }
  if (!content) content = String(error);
  return notify({title, message: content, status: 'error', stayOnScreen: true});
}

export default {notify, dismiss, showError};
