import {map} from 'lodash';
import React from 'react';

import {useMainSidebar} from '../MainSidebarProvider';
import {Widget} from './Widget';
import {WidgetPosition} from '../types';

type WidgetsProps = {
  position: WidgetPosition;
}

export const Widgets: React.FC<WidgetsProps> = ({
  position
}) => {
  const {widgets} = useMainSidebar();
  return (
    <>
      {map(widgets, ({id, widget}) => widget.position === position && (
        <Widget
          key={id}
          title={widget.title}
        >
          {widget.children}
        </Widget>
      ))}
    </>
  );
};
