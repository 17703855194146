import {clamp} from 'lodash';
import {FC, useRef, useState} from 'react';
import {Rnd} from 'react-rnd';
import {Button, Header, Portal} from 'semantic-ui-react';

import {ReactComponent as ApstraLogo} from '../../../styles/icons/apstra-logo.svg';

import {GlobalTopicsTree} from './GlobalTopicsTree';
import {useGlobalHelp} from './GlobalHelpContext';
import {GlobalHelpPage} from './GlobalHelpPage';
import {NextPreviousNavigation} from './NextPreviousNavigation';
import {PageBreadcrumbs} from './PageBreadcrumbs';

import './GlobalHelpModal.less';

const MIN_WIDTH = 380;
const MIN_HEIGHT = 400;

type WindowGeometry = {
  x: number;
  y: number;
  width: number;
  height: number;
}

const limitWindowGeometry = (geometry: WindowGeometry): WindowGeometry => {
  const width = clamp(geometry.width, MIN_WIDTH, window.innerWidth);
  const height = clamp(geometry.height, MIN_HEIGHT, window.innerHeight);
  const x = clamp(geometry.x, 0, Math.max(0, window.innerWidth - width));
  const y = clamp(geometry.y, 0, Math.max(0, window.innerHeight - height));
  return {x, y, width, height};
};

export const GlobalHelpModal: FC = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {canGoBack, canGoForward, goHistoryBack, goHistoryForward, isOpen,
    navigate, setIsOpen, setPreferences, url,
    preferences = {
      x: 0.5 * window.innerWidth,
      y: 0.5 * window.innerHeight,
      width: 400,
      height: 400
    }} = useGlobalHelp();
  const limitedLocation = limitWindowGeometry(preferences);
  const [position, setPosition] = useState({x: limitedLocation.x, y: limitedLocation.y});
  return (
    <Portal open={isOpen}>
      <Rnd
        className='global-help-modal-resize-drag'
        default={limitedLocation}
        dragHandleClassName='global-help-modal-container > .header'
        enableResizing={{
          bottom: true,
          bottomRight: true,
          right: true,
        }}
        minHeight={MIN_HEIGHT}
        minWidth={MIN_WIDTH}
        onDragStop={(_, data) => {
          const pos = {
            x: clamp(data.x, 0, Math.max(0, window.innerWidth - limitedLocation.width)),
            y: clamp(data.y, 0, Math.max(0, window.innerHeight - limitedLocation.height))
          };
          setPreferences?.({...limitedLocation, ...pos});
          setPosition(pos);
        }}
        onResizeStop={() => setPreferences?.({
          ...limitedLocation,
          width: Math.min(modalRef.current!.clientWidth, window.innerWidth),
          height: Math.min(modalRef.current!.clientHeight, window.innerHeight)
        })}
        onDrag={(_, {x, y}) => setPosition({x, y})}
        position={position}
      >
        <div ref={modalRef} className='global-help-modal-container'>
          <div className='header'>
            <div className='logo'>
              <ApstraLogo /><Header as='h3'>{'Apstra Help'}</Header>
            </div>
            <div>
              <Button basic disabled={!canGoBack} icon='arrow left' onClick={() => goHistoryBack()} size='mini' />
              <Button
                basic
                disabled={!canGoForward}
                icon='arrow right'
                onClick={() => goHistoryForward()}
                size='mini'
              />
              <Button basic icon='home' onClick={() => navigate(null)} size='mini' />
              <Button basic icon='close' onClick={() => setIsOpen(false)} size='mini' />
            </div>
          </div>
          {url && <PageBreadcrumbs />}
          <div className='content'>
            {url && <GlobalHelpPage key={url} />}
            <GlobalTopicsTree />
          </div>
          <NextPreviousNavigation />
        </div>
      </Rnd>
    </Portal>
  );
};
