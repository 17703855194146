/* eslint-disable @typescript-eslint/no-explicit-any */
import {find} from 'lodash';

import {SafeHtmlOptions, safeHtml} from './safe-html/safe-html';

/**
 *
 * @param HTMLString Raw HTML string
 * @param getDOM True (HTML element)/ False (return sanitized HTML string)
 * @param callback
 * @param options { enableAttribTransform = false }
 */
export const sanitizeRawHTML = (
  HTMLString: string,
  getDOM = true,
  callback: any,
  options: any = {}
) => {
  const {enableAttribTransform = false} = options;
  const safeHTMLConfig: SafeHtmlOptions = enableAttribTransform ? {
    afterSanitizeAttributes: (node: Element) => transformAttributes(node, options),
  } : {};

  if (callback) {
    callback(safeHtml(HTMLString, safeHTMLConfig, getDOM) as unknown);
  }
};

/**
 * converting HTML to match Help widget HTML with attributes and styles.
 * @param node
 * @param options
 * @returns transfmed HTML string / element.
 */
const transformAttributes = (node: Element, options?: any) => {
  const nodeName = node.nodeName.toLowerCase();
  const nodeClassList = node.classList;

  if (nodeName === 'a') {
    return transformAnchorHref(node, options);
  } else if (nodeName === 'img') {
    return transformImageSrc(node, options);
  } else if (nodeName === 'span') {
    if (nodeClassList.contains('figcap')) {
      node.innerHTML = `${node.innerHTML} <span class="image-expand-icon" title="Expand"></span>`;
    } else if (nodeClassList.contains('tablecap')) {
      node.innerHTML = `${node.innerHTML} <span class="table-expand-icon" title="Expand"></span>`;
    }
  } else if (['ul', 'ol', 'li'].includes(nodeName)) {
    return transformLists(node, nodeName);
  } else if (
    ['table', 'thead', 'tfoot', 'tbody', 'tr', 'th', 'td'].includes(nodeName)
  ) {
    return transformTable(node, nodeName);
  }

  return node;
};

/**
 * Process the Href if there is any absolute / relative path
 * Scroll(#) / internal (starts with . or ..) / External (full path URL)
 * @param node
 * @param param1
 * @returns
 */
const transformAnchorHref = (node: Element, {topicLinks}) => {
  // eslint-disable-next-line no-useless-escape
  const domainMatchRegex = /(http(s?):)?\/\/(?:www\.)?([a-z0-9\-]+)(?:\.[a-z\.]+[\/]?)\/|^\/|^(\..*\.\/)/i;
  const hrefValue = node.getAttribute('href');
  const hrefPath = hrefValue && hrefValue.replace(domainMatchRegex, '');

  if (hrefPath) {
    node.classList.add('help-topic-anchor');

    if (!hrefPath.startsWith('#')) {
      const foundUrl = find(topicLinks, (topic) => topic.includes(hrefPath)) ?? hrefPath;
      node.setAttribute('href', foundUrl);
      node.classList.add('internal-link');
      node.setAttribute('data-link', foundUrl);
    } else {
      node.classList.add('scroll-link');
    }
  }

  return node;
};

/**
 *
 * @param node
 * @param imagePaths
 * @returns full URL for the image with added path prefix
 */
const transformImageSrc = (node: Element, imagePaths: any) => {
  const {remoteImagePath, localImagePath} = imagePaths;
  // eslint-disable-next-line no-useless-escape
  const domainMatchRegex = /(http(s?):)?\/\/(?:www\.)?([a-z0-9\-]+)(?:\.[a-z\.]+[\/]?)\/|^\//i;
  const imageMatchRegex = /([^\s]+(\.(jpe?g|png|gif|bmp))$)/gi;
  const srcValue = node.getAttribute('src');
  const srcPath = srcValue && srcValue.replace(domainMatchRegex, '');

  if (srcPath && imageMatchRegex.test(srcPath)) {
    node.setAttribute(
      'src',
      `${remoteImagePath}/${srcPath}`,
    );
    node.setAttribute(
      'onerror',
      `this.src="${localImagePath}/${srcPath}"`,
    );
    node.classList.add('help-topic-image');
  } else {
    node.remove();
  }

  return node;
};

/**
 * We can use this function to transform any list with Help-topic class
 * @param node
 * @param nodeName
 * @returns
 */
const transformLists = (node: Element, nodeName: string) => {
  node.classList.add(`help-topic-${nodeName}`);

  return node;
};

/**
 * @param node
 * @param options
 * @returns The table element
 */
const transformTable = (node: Element, nodeName: string) => {
  node.removeAttribute('style');
  node.removeAttribute('width');
  node.classList.add(`help-topic-${nodeName}`);

  return node;
};
