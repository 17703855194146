import {isNil, isArray} from 'lodash';
import {Component} from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import Field from './Field';
import DropdownControl from './DropdownControl';
import generateOptionsFromSchema from '../generateOptionsFromSchema';

@observer
export default class DropdownInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get multiple() {
    const {multiple, options, schema = {}} = this.props;
    if (!isNil(multiple)) return multiple;
    if (!isNil(options)) return false;
    return !!(schema.type === 'array' && schema.items);
  }

  @computed get options() {
    const {options, schema = {}} = this.props;
    if (!isNil(options)) return options;
    return generateOptionsFromSchema(this.multiple ? schema.items : schema) ?? [];
  }

  @computed get value() {
    const {value} = this.props;
    if (this.multiple) {
      return isArray(value) ? [...value] : [];
    } else {
      return value ?? null;
    }
  }

  render() {
    const {
      multiple, options, value,
      props: {
        name, schema, required, disabled, errors, fieldProps, onChange,
        placeholder, loading, clearable, selectedValueLabel, additionLabel,
      }
    } = this;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <DropdownControl
          search={!disabled}
          multiple={multiple}
          disabled={disabled}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          loading={loading && !disabled}
          clearable={clearable && !disabled}
          selectedValueLabel={selectedValueLabel}
          additionLabel={additionLabel}
        />
      </Field>
    );
  }
}
