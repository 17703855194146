export type HelpSectionType = {
  title: string;
  children: (HelpSectionType | HelpPageType)[]
}

export type HelpPageType = {
  title: string;
  url: string;
  xid: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHelpPageType = (o: any): o is HelpPageType => {
  return !!o && 'url' in o;
};

export type WindowLocation = {
  width: number;
  height: number;
  x: number;
  y: number;
}
