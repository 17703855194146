import {omit} from 'lodash';
import {remove, uniqueId} from 'lodash/fp';
import React from 'react';

import {WidgetPosition} from './types';

type Widget = React.PropsWithChildren<{
  position: WidgetPosition,
  title: string | React.ReactElement
}>;

type RegisteredWidget = {
  widget: Widget;
  id: string;
}

export type Button = React.PropsWithChildren<{
  children?: React.ReactElement,
  icon: string | React.ReactElement,
  'aria-label': string;
  label?: string | React.ReactElement,
  menuKey?: string;
  isMenu?: boolean;
  right?: boolean;
}>;

export type RegisteredButton = {
  button: Button;
  id: string;
}

export type MenuItem = {
  active?: boolean;
  href?: string;
  iconClass?: string;
  itemClass?: string;
  label: string;
  menuKey?: string;
  onClick?: () => void;
  order?: number;
  parent?: string;
  target?: string;
}

type MainSidebarContextValue = {
  addWidget: (id: string, widget: Widget) => void;
  removeWidget: (id: string) => void;
  widgets: RegisteredWidget[];

  addButton: (id: string, button: Button) => void;
  removeButton: (id: string) => void;
  buttons: RegisteredButton[];

  addMenuItem: (menuItem: MenuItem) => void;
  removeMenuItem: (menuKey: string) => void;
  menuItems: MenuItem[];
};

const MainSidebarContext = React.createContext<MainSidebarContextValue | null>(null);

export const MainSidebarProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [widgets, setWidgets] = React.useState<RegisteredWidget[]>([]);
  const [buttons, setButtons] = React.useState<RegisteredButton[]>([]);
  const [menuItems, setMenuItem] = React.useState<MenuItem[]>([]);

  const addWidget = React.useCallback(
    (id: string, widget: Widget) => setWidgets((prev) => [...prev, {id, widget}]),
    []
  );
  const removeWidget = React.useCallback(
    (id: string): void => setWidgets(remove<RegisteredWidget>({id})),
    []
  );
  const addButton = React.useCallback(
    (id: string, button: Button) => setButtons((prev) => [...prev, {id, button}]),
    []
  );
  const removeButton = React.useCallback(
    (id: string): void => setButtons(remove<RegisteredButton>({id})),
    []
  );
  const addMenuItem = React.useCallback(
    (menuItem: MenuItem) => {
      if (!menuItem.menuKey) throw new Error(`menuKey can't be empty. ${menuItem && JSON.stringify(menuItem)}`);
      setMenuItem((prev) => [...prev, menuItem]);
    },
    []
  );
  const removeMenuItem = React.useCallback(
    (menuKey: string): void => setMenuItem(remove<MenuItem>({menuKey})),
    []
  );
  const value = React.useMemo<MainSidebarContextValue>(() => ({
    addWidget,
    removeWidget,
    widgets,
    addButton,
    removeButton,
    buttons,
    addMenuItem,
    removeMenuItem,
    menuItems
  }), [addWidget, removeWidget, widgets, addButton, removeButton, buttons, addMenuItem, removeMenuItem, menuItems]);
  return (
    <MainSidebarContext.Provider value={value}>
      {children}
    </MainSidebarContext.Provider>
  );
};

export const useMainSidebar = (): MainSidebarContextValue => {
  const contextData = React.useContext(MainSidebarContext);
  if (contextData === null) {
    throw new Error('There is no MainSidebar context');
  }
  return contextData;
};

export const MainSidebarWidget: React.FC<Widget> = React.memo((props) => {
  const [id] = React.useState(() => uniqueId('main-sidebar-widget-'));
  const {addWidget, removeWidget} = useMainSidebar();
  React.useEffect(
    () => {
      addWidget(id, props);
      return () => removeWidget(id);
    },
    [addWidget, id, removeWidget, props]
  );
  return null;
});

export const MainSidebarButton: React.FC<React.PropsWithChildren<Button>> = React.memo((props) => {
  const [id] = React.useState(() => uniqueId('main-sidebar-button-'));
  const {addButton, removeButton} = useMainSidebar();
  React.useEffect(
    () => {
      addButton(id, props);
      return () => removeButton(id);
    },
    [addButton, id, removeButton, props]
  );
  const contextValue = React.useMemo(() => ({parent: props.menuKey}), [props.menuKey]);
  return props.isMenu ? (
    <MainSidebarMenuItemContext.Provider value={contextValue}>
      {props.children}
    </MainSidebarMenuItemContext.Provider>
  ) : null;
});

type MainSidebarMenuItemContextValue = {
  parent?: string;
}

const MainSidebarMenuItemContext = React.createContext<MainSidebarMenuItemContextValue | null>(null);

export const MainSidebarMenuItem: React.FC<React.PropsWithChildren<MenuItem>> = React.memo((props) => {
  const [id] = React.useState(() => uniqueId('main-sidebar-menu-item-'));
  const {addMenuItem, removeMenuItem} = useMainSidebar();
  const {parent: contextParent} = React.useContext(MainSidebarMenuItemContext) ?? {};
  React.useEffect(
    () => {
      const menuKey = props.menuKey ?? id;
      addMenuItem({...omit(props, 'children'), menuKey, parent: props.parent ?? contextParent});
      return () => removeMenuItem(menuKey);
    },
    [addMenuItem, removeMenuItem, props, contextParent, id]
  );
  const contextValue = React.useMemo(() => ({parent: props.menuKey}), [props.menuKey]);
  return (
    <MainSidebarMenuItemContext.Provider value={contextValue}>
      {props.children}
    </MainSidebarMenuItemContext.Provider>
  );
});
