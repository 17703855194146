import ace from 'ace-builds';

import './ace-theme-apstra-light.less';

ace.define('ace/theme/apstra-light', ['require', 'exports'], (require, exports) => {
  'use strict';
  exports.isDark = false;
  exports.cssClass = 'ace-apstra-light';
  exports.cssText = '.ace-apstra-light {}';
  exports.$id = 'ace/theme/apstra-light';
});

ace.require(['ace/theme/apstra-light']);
