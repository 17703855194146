/* eslint-disable react/no-danger */
import {useQuery} from '@tanstack/react-query';
import {findKey, includes, noop} from 'lodash';
import {useEffect, useState} from 'react';
import {Message} from 'semantic-ui-react';

import Loader from '../Loader';
import {useGlobalHelp} from './GlobalHelpContext';
import {sanitizeRawHTML} from './external';
import {queryKeys} from './queryKeys';

import './GlobalHelpPage.less';

export const GlobalHelpPage: React.FC = () => {
  const {basePath, localImagePath, navigate, orderedTopicUrls, topicUrlById, url} = useGlobalHelp();
  const [content, setContent] = useState<HTMLElement>();
  const {
    data: pageData,
    isLoading,
    isError
  } = useQuery({
    queryKey: queryKeys.helpPage(url),
    queryFn: ({signal}) => fetch(basePath + url, {signal})
      .then((response) => response.ok ? response.text() : Promise.resolve(null)),
    gcTime: Infinity,
    staleTime: Infinity
  });

  useEffect(() => {
    if (!pageData || !url) return;
    sanitizeRawHTML(pageData, true, setContent, {
      localImagePath,
      topicLinks: orderedTopicUrls,
      baseUrl: url,
      enableAttribTransform: true,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]); // only on pageData change

  useEffect(() => {
    const topicId = findKey(topicUrlById, (value) => value === url);
    if (topicId) {
      // eslint-disable-next-line no-console
      console.log('topicId', topicId);
    }
  }, [topicUrlById, url]);

  return (
    <div
      className='global-help-page'
      onKeyDown={noop}
      onClick={(e) => {
        if ((e.target as HTMLElement).nodeName !== 'A') return;
        e.preventDefault();
        e.stopPropagation();
        const link = e.target as HTMLAnchorElement;
        const href = link.getAttribute('href');
        if (includes(orderedTopicUrls, href)) {
          navigate(href);
        } else {
          // eslint-disable-next-line no-console
          console.warn('link not found', href, 'current is', url);
        }
      }}
      role='link'
      tabIndex={0}
    >
      {isError && <Message error content='Help page loading failed' />}
      {isLoading && <Loader size='small' text='Loading page content...' />}
      {content && <span
        className='topic-body'
        dangerouslySetInnerHTML={{__html: content?.innerHTML}}
      />}
    </div>
  );
};
