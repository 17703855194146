export const schemaPropertyDefaultValues = {
  string: '',
  number: null,
  integer: null,
  boolean: false,
  null: null,
  array: [],
  object: {},
};

export default function generatePropertyFromSchema(schema) {
  let value;
  if ('default' in schema) {
    value = schema.default;
  } else {
    value = schemaPropertyDefaultValues[schema.type || 'string'];
  }
  return value;
}
