const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

// Returns a comparison function that sorts strings by their natural order
// rather than their ASCII values, as the built-in sort function does.
// Comparison is case insensitive.
export default function natsort(str1, str2) {
  return collator.compare(str1, str2);
}

export function multipleNatsort({items, iteratees, directions}) {
  return Array.isArray(items) ? [...items].sort((a, b) => {
    for (let index = 0; index < iteratees.length; index++) {
      const result = natsort(iteratees[index](a), iteratees[index](b));
      if (result) {
        return (directions?.[index] ?? 'asc') === 'asc' ? result : -result;
      }
    }
    return 0;
  }) : items;
}
