import {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, makeObservable, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {transform, isString, isArray} from 'lodash';

@observer
export default class SvgTextLengthMeasurer extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    style: PropTypes.object,
    onMeasure: PropTypes.func
  };

  @observable measured = false;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.disposeValueReaction = reaction(
      () => this.props.value,
      this.resetMeasured
    );
  }

  componentWillUnmount() {
    this.disposeValueReaction();
  }

  @action
  measureTextLengths = (node) => {
    if (node && !this.measured) {
      let valueTextLength = 0;
      if (isString(this.props.value)) {
        valueTextLength = this.getTextLength(node, this.props.value);
      } else if (isArray(this.props.value)) {
        valueTextLength = transform(this.props.value, (result, value) => {
          result[value] = this.getTextLength(node, value);
        }, {});
      }
      this.props.onMeasure?.(valueTextLength);
    }
    this.measured = true;
  };

  @action
  resetMeasured = () => {
    this.measured = false;
  };

  getTextLength = (node, value) => {
    node.textContent = value;
    return Math.ceil(node.getComputedTextLength());
  };

  render() {
    const {measured, measureTextLengths, props: {style}} = this;
    return (
      !measured && <text ref={measureTextLengths} style={style} />
    );
  }
}
