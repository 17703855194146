import {Component} from 'react';
import PropTypes from 'prop-types';
import {Accordion, Button, Form, Icon} from 'semantic-ui-react';
import {observable, action, computed, toJS, reaction, comparer, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {map, transform, isEqual, isUndefined, isString, chunk, isNil} from 'lodash';

import FormFragment from './FormFragment';
import generatePropertyFromSchema from '../generatePropertyFromSchema';
import AppliedQuery from './AppliedQuery';

import './SearchBox.less';

@observer
export default class SearchBox extends Component {
  static propTypes = {
    schema: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    filters: PropTypes.object,
    errors: PropTypes.object,
    renderers: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    valueProps: PropTypes.object,
    valueInputProps: PropTypes.object,
    applyOnChange: PropTypes.bool,
    asAccordion: PropTypes.bool,
    splitIntoColumns: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    filters: {},
    errors: {},
    renderers: [],
    valueProps: {},
    valueInputProps: {},
    asAccordion: false,
    splitIntoColumns: 1
  };

  @observable active = false;

  @computed get emptyFilters() {
    return transform(this.props.schema, (result, {name, schema}) => {
      result[name] = generatePropertyFromSchema(schema);
    }, {});
  }

  @computed get currentFilters() {
    const {filters} = this.props;
    return transform(this.props.schema, (result, {name}) => {
      const filter = toJS(filters[name]);
      if (!isNil(filter?.value ?? filter)) result[name] = filter;
    }, {});
  }

  filters = observable(this.emptyFilters);

  @computed get newFilters() {
    const {filters, emptyFilters} = this;
    return transform(this.props.schema, (result, {name}) => {
      const filter = toJS(filters[name]);
      if (!isEqual(filter, emptyFilters[name])) result[name] = filter;
    }, {});
  }

  @action
  show = () => {
    this.active = true;
  };

  @action
  hide = () => {
    this.active = false;
  };

  @action
  toggle = () => {
    this.active = !this.active;
  };

  hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      this.hide();
    }
  };

  toggleOnEnter = (e) => {
    if (e.key === 'Enter') {
      this.toggle();
    }
  };

  @action
  apply = () => {
    this.hide();
    this.applyFilters(this.newFilters);
  };

  @action
  clear = () => {
    this.hide();
    this.clearFilters();
    this.applyFilters({});
  };

  applyFilters(filters) {
    this.props.onChange(filters);
  }

  @action
  clearFilters = () => {
    for (const {name} of this.props.schema) {
      this.filters[name] = this.emptyFilters[name];
    }
  };

  @action
  updateFilters = (filters) => {
    for (const {name} of this.props.schema) {
      this.filters[name] = isUndefined(filters[name]) ? this.emptyFilters[name] : filters[name];
    }
  };

  @action
  setFilterValue = (name, value) => {
    this.filters[name] = (isString(value) && value === '') ? this.emptyFilters[name] : value;
    if (this.props.applyOnChange) {
      this.applyFilters(this.newFilters);
    }
  };

  constructor(props = {}) {
    super(props);
    makeObservable(this);
    this.disposeFiltersUpdater = reaction(
      () => [this.props.filters, this.props.schema],
      ([filters]) => this.updateFilters(filters),
      {equals: comparer.structural, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeFiltersUpdater();
  }

  renderFormFragment(schema) {
    const {filters} = this;
    const {disabled, errors, renderers, valueInputProps} = this.props;

    return (
      <FormFragment
        schema={schema}
        values={filters}
        errors={errors}
        disabled={disabled}
        renderers={map(renderers, 'renderValueInput')}
        onChange={this.setFilterValue}
        {...valueInputProps}
      />
    );
  }

  renderInner() {
    const {schema, splitIntoColumns, disabled, applyOnChange} = this.props;
    return (
      <>
        <div className='search-form-container'>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className='ui form' onKeyDown={this.hideOnEscape} tabIndex={-1}>
            {
              splitIntoColumns > 1 ?
                map(
                  chunk(schema, splitIntoColumns),
                  (schemaChunk, index) => (
                    <Form.Group key={index} widths='equal'>{this.renderFormFragment(schemaChunk)}</Form.Group>
                  )
                )
              :
                this.renderFormFragment(schema)
            }
          </div>
        </div>
        <div className='search-buttons'>
          {!applyOnChange && <Button
            primary
            icon='search'
            content='Apply'
            disabled={disabled}
            onClick={this.apply}
          />}
          <Button
            className='ghost'
            icon='undo'
            content='Clear'
            disabled={disabled}
            onClick={this.clear}
          />
        </div>
      </>
    );
  }

  render() {
    const {active} = this;
    const {asAccordion} = this.props;

    const innerContent = this.renderInner();

    return asAccordion ?
      <Accordion styled className='searchbox'>
        <Accordion.Title
          active={active}
          onClick={this.toggle}
          onKeyDown={this.toggleOnEnter}
          role='button'
          tabIndex={0}
        >
          <Icon name='dropdown' />
          {'Query: '}
          <AppliedQuery
            filters={this.currentFilters}
            schema={this.props.schema}
            renderers={this.props.renderers}
            valueProps={this.props.valueProps}
            ifEmpty='All'
          />
        </Accordion.Title>
        <Accordion.Content active={active}>
          {innerContent}
        </Accordion.Content>
      </Accordion>
    :
      <div className='searchbox'>
        {innerContent}
      </div>;
  }
}
