import {Component} from 'react';
import {flatMap, find, isNil, isArray, capitalize} from 'lodash';

export default class Value extends Component {
  static defaultProps = {
    renderers: []
  };

  renderEmptyValue({value}) {
    if (value === null || value === undefined) {
      return 'No value';
    }
    return null;
  }

  renderEnumWithLabel({value, schema: {oneOf, anyOf} = {}}) {
    if (typeof value === 'string' && (isArray(oneOf) || isArray(anyOf))) {
      const item = find(oneOf || anyOf, {const: value});
      if (item) {
        return item.title || item.const;
      }
    }
    return null;
  }

  renderPrimitive({value}) {
    if (typeof value === 'number' || typeof value === 'string') {
      return value;
    }
    return null;
  }

  renderBoolean({value}) {
    if (typeof value === 'boolean') {
      return capitalize(String(value));
    }
    return null;
  }

  renderArray({value, name, schema}) {
    if (isArray(value)) {
      return flatMap(value, (subvalue, index) =>
        (index ? [', '] : []).concat(this.renderValue({value: subvalue, name, schema: schema?.items}))
      );
    }
    return null;
  }

  renderAsJSON({value}) {
    return JSON.stringify(value);
  }

  renderValue(props) {
    let result = null;
    for (const renderingMethod of [
      ...this.props.renderers,
      'renderEmptyValue',
      'renderEnumWithLabel',
      'renderPrimitive',
      'renderBoolean',
      'renderArray',
      'renderAsJSON'
    ]) {
      result = typeof renderingMethod === 'string' ?
        this[renderingMethod](props) :
        renderingMethod.call(this, props);
      if (!isNil(result)) return result;
    }
    return null;
  }

  render() {
    const props = {...this.props};
    delete props.renderers;
    return this.renderValue(props);
  }
}
