import {Component} from 'react';
import PropTypes from 'prop-types';
import {Label} from 'semantic-ui-react';
import {map, flatMap, isPlainObject, isArray} from 'lodash';

export default class ValidationErrors extends Component {
  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    pointing: Label.propTypes.pointing
  };

  static defaultProps = {
    errors: [],
    pointing: true
  };

  flatMapErrors(error) {
    if (isPlainObject(error)) {
      return map(error, (value, key) => `${key}: ${value}`);
    } else if (isArray(error)) {
      return error;
    } else {
      return String(error);
    }
  }

  render() {
    const {errors, pointing} = this.props;
    if (!errors.length) return null;
    return (
      <Label basic color='red' pointing={pointing}>
        {map(flatMap(errors, this.flatMapErrors), (error, index, flatErrors) =>
          <div key={index}>
            {flatErrors.length > 1 && '• '}
            {error}
          </div>
        )}
      </Label>
    );
  }
}
