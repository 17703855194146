import {Fragment} from 'react';
import {Popup, Icon} from 'semantic-ui-react';
import {isUndefined} from 'lodash';
import PropTypes from 'prop-types';

import Field, {POSITION_TOOLTIP} from './Field';
import Checkbox from './Checkbox';

const BooleanInput = ({
  name, schema: {title, description}, value = false, required, disabled,
  errors, onChange, appearance, showLabelInline, fieldProps
}) => {
  const {descriptionPosition, className, ...otherFieldProps} = fieldProps;
  const label = (
    <Fragment key='boolean-input-label'>
      {title || name}
      {(description && descriptionPosition === POSITION_TOOLTIP) ?
        <Popup
          key='popup'
          trigger={<Icon name='info circle' aria-label={description} role='img' />}
          content={description}
          position='right center'
          wide
        />
      :
        null
      }
    </Fragment>
  );
  const shownInline = isUndefined(showLabelInline) ?
    appearance === 'checkbox' :
    showLabelInline;

  return (
    <Field
      {...otherFieldProps}
      label={shownInline ? undefined : label}
      description={descriptionPosition === POSITION_TOOLTIP ? undefined : description}
      required={required}
      disabled={disabled}
      errors={errors}
      className={className}
    >
      <Checkbox
        label={shownInline ? label : undefined}
        toggle={appearance === 'toggle'}
        slider={appearance === 'slider'}
        checked={value}
        onChange={() => onChange(!value)}
      />
    </Field>
  );
};

BooleanInput.propTypes = {
  appearance: PropTypes.oneOf(['checkbox', 'toggle', 'slider']),
};

BooleanInput.defaultProps = {
  appearance: 'checkbox',
  fieldProps: {},
};

export default BooleanInput;
