import {get, includes, every, some, toLower, partial, isEmpty, isFunction, isBoolean} from 'lodash';

function getValue(object, valueGetter, params, defaultValue) {
  return isFunction(valueGetter) ? valueGetter(object, params) : get(object, valueGetter, defaultValue);
}

export function stringFilterer(itemPath, filtersPath) {
  return function({item, filters, params}) {
    const filterValue = getValue(filters, filtersPath, params, '');
    if (!filterValue.length) return true;
    const value = getValue(item, itemPath, params, '');
    return includes(toLower(value), toLower(filterValue));
  };
}

export function objectFilterer(itemPath, filtersObjectPath, filtersStrictPath = null, predicate) {
  return function({item, filters, params}) {
    const filterValue = getValue(filters, filtersObjectPath, params, null);
    if (isEmpty(filterValue)) return true;
    const strict = isBoolean(filtersStrictPath) ? filtersStrictPath
      : filtersStrictPath ? getValue(filters, filtersStrictPath, params, true)
      : true;
    const itemValue = getValue(item, itemPath, params, null);
    return (strict ? every : some)(filterValue, partial(predicate, itemValue));
  };
}

export function arrayFilterer(itemPath, filtersArrayPath, filtersStrictPath) {
  return objectFilterer(
    itemPath,
    filtersArrayPath,
    filtersStrictPath,
    (itemValue, filterSubvalue) => includes(itemValue, filterSubvalue)
  );
}
