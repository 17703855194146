import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {computed, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {get, isFunction, isEmpty, transform, find, omitBy} from 'lodash';

import {multipleNatsort} from '../natsort';
import {DEFAULT_PAGE_SIZE} from './Pagination';

@observer
export default class DataFilter extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    items: PropTypes.array,
    schema: PropTypes.array,
    params: PropTypes.object,
    activePage: PropTypes.number,
    pageSize: PropTypes.number,
    filterers: PropTypes.array,
    filters: PropTypes.object,
    sorting: PropTypes.object,
    selection: PropTypes.object,
    updateSelection: PropTypes.func,
    getItemKey: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    activePage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    filterers: [],
    filters: {},
    sorting: {},
  };

  @computed get filteredItems() {
    const {filterers, filters, items, params} = this.props;
    if (!filterers.length) {
      return items;
    } else {
      return items.filter((item) => {
        for (const filterer of filterers) {
          if (!filterer({item, filters, params})) return false;
        }
        return true;
      });
    }
  }

  @computed get sortedItems() {
    const {sorting, schema, params} = this.props;
    const items = this.filteredItems;
    if (isEmpty(sorting)) {
      return items;
    } else {
      const [iteratees, directions] = transform(sorting, ([iteratees, directions], direction, name) => {
        const columnSchema = find(schema, {name});
        if (columnSchema) {
          const valueGetter = columnSchema.value;
          const iteratee = isFunction(valueGetter) ?
            (item) => valueGetter({name, item, params})
          :
            (item) => get(item, valueGetter, null);
          iteratees.push(iteratee);
          directions.push(direction);
        }
      }, [[], []]);
      return multipleNatsort({items, iteratees, directions});
    }
  }

  @computed get slicedItems() {
    const {activePage, pageSize} = this.props;
    const items = this.sortedItems;
    if (pageSize === null) {
      return items;
    } else {
      const start = (activePage - 1) * pageSize;
      const end = start + pageSize;
      return items.slice(start, end);
    }
  }

  constructor(props) {
    super(props);

    makeObservable(this);

    if (this.props.selection && !this.props.getItemKey) {
      throw new Error('getItemKey is not specified');
    }

    if (this.props.selection) {
      this.disposeItemsUpdater = reaction(
        () => transform(this.filteredItems, (result, item) => {
          result[this.props.getItemKey(item)] = true;
        }, {}),
        (itemKeys) => this.props.updateSelection(
          omitBy(this.props.selection, (value, key) => !itemKeys[key])
        )
      );
    }
  }

  componentWillUnmount() {
    if (this.props.selection) this.disposeItemsUpdater();
  }

  render() {
    const {children} = this.props;
    const props = {items: this.slicedItems, allItems: this.filteredItems, totalCount: this.filteredItems.length};
    return isFunction(children) ? children(props) : React.cloneElement(children, props);
  }
}
