import moment from 'moment';
import {isString} from 'lodash';

function parseTimestampNatively(timestamp) {
  return isString(timestamp) ? new Date(timestamp) : null;
}

function parseTimestampUsingMoment(timestamp) {
  return isString(timestamp) ? moment(timestamp, moment.ISO_8601).toDate() : null;
}

function doesNativeParsingWorkFine() {
  return (
    Date.parse('2020-02-02T20:20:20.202000Z') === 1580674820202 &&
    Date.parse('2020-02-02T20:20:20.202000+0000') === 1580674820202 &&
    Date.parse('2020-02-02T20:20:20.202000+0400') === 1580660420202
  );
}

const parseTimestamp = doesNativeParsingWorkFine() ? parseTimestampNatively : parseTimestampUsingMoment;

export default parseTimestamp;
