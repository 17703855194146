import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  observable,
  action,
  reaction,
  computed,
  comparer,
  makeObservable,
} from 'mobx';
import {observer} from 'mobx-react';
import {isNil, isFunction, omit, isEqual, isObject} from 'lodash';
import hash from 'object-hash';
import LruCache from 'lru-cache';

import Loader from './Loader';
import FetchDataError from './FetchDataError';

@observer
export default class FetchData extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    pollingInterval: PropTypes.number,
    fetchParams: PropTypes.object,
    fetchData: PropTypes.func,
    customLoader: PropTypes.bool,
    showLoaderOnRefetch: PropTypes.bool,
    refetchComparer: PropTypes.func,
    fetchKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    fetchParams: {},
    showLoaderOnFetchParamsUpdate: true,
    refetchComparer: comparer.structural,
  };

  static cache = new LruCache({
    max: 100, // max size of cache
    ttl: 10 * 60 * 1000, // in ms
  });

  @observable.ref fetchDataError = null;
  @observable.ref data = null;
  @observable loaderVisible = true;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get pollingInterval() {
    let {pollingInterval} = this.props;
    if (pollingInterval === undefined) {
      pollingInterval = React.Children.only(this.props.children).type.pollingInterval;
    }
    return pollingInterval;
  }

  @action
  fetchData = async ({showLoader = false, fetchParams = this.props.fetchParams} = {}) => {
    let {fetchData} = this.props;
    const {fetchKey, providedData} = this.props;
    if (!fetchData) {
      const ChildComponent = React.Children.only(this.props.children).type;
      fetchData = ChildComponent.fetchData.bind(ChildComponent);
    }
    if (showLoader && isNil(providedData)) {
      this.loaderVisible = true;
    }
    this.abortController = new AbortController();
    const {signal} = this.abortController;
    fetchParams = {...fetchParams, previousData: this.data, signal};
    let fetchedData, fetchDataError;
    if (isNil(providedData)) {
      const fetchHash = isObject(fetchKey) ? hash(fetchKey) : fetchKey;
      if (fetchHash) {
        const oldFetchedData = FetchData.cache.get(fetchHash);
        if (oldFetchedData) {
          this.handleFetchData(oldFetchedData);
        }
      }

      try {
        fetchedData = await fetchData(fetchParams);
        if (fetchKey) {
          FetchData.cache.set(fetchHash, fetchedData);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          fetchDataError = error;
        }
      }
    } else {
      fetchedData = providedData;
    }
    if (!signal.aborted) {
      this.handleFetchData(fetchedData, fetchDataError);
      if (this.pollingInterval) {
        this.startPolling();
      }
    }
  };

  @action
  handleFetchData(fetchedData, fetchDataError) {
    this.loaderVisible = false;
    this.fetchDataError = isNil(fetchDataError) ? null : fetchDataError;
    this.data = fetchedData;
  }

  startPolling() {
    this.activePollingTimeout = setTimeout(this.fetchData, this.pollingInterval);
  }

  stopPolling() {
    clearTimeout(this.activePollingTimeout);
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  refetchData = async ({fetchParams = {}, ...restParams} = {}) => {
    this.stopPolling();
    await this.fetchData({fetchParams: {...this.props.fetchParams, ...fetchParams}, ...restParams});
  };

  componentDidMount() {
    this.disposeFetchParamsReaction = reaction(
      () => this.props.fetchParams,
      (fetchParams) => this.refetchData({fetchParams, showLoader: this.props.showLoaderOnFetchParamsUpdate}),
      {equals: this.props.refetchComparer, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.stopPolling();
    this.disposeFetchParamsReaction();
  }

  render() {
    const {loaderVisible, fetchDataError, refetchData, data} = this;
    const {customLoader, children, ...props} = this.props;
    if (customLoader) {
      Object.assign(props, {loaderVisible, fetchDataError});
    } else if (loaderVisible) {
      return <Loader />;
    } else if (fetchDataError) {
      return <FetchDataError error={fetchDataError} />;
    }
    const newProps = {refetchData, ...data, ...props};
    return isFunction(children) ? children(newProps) : React.cloneElement(children, newProps);
  }
}

export const refetchExcludeParametersComparer = (excludeParameters) => (a, b) => {
  const params1 = omit(a, excludeParameters);
  const params2 = omit(b, excludeParameters);
  return isEqual(params1, params2);
};
