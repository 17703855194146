import {PureComponent} from 'react';

import Field from './Field';

import StringListDropdownControl from './StringListDropdownControl';

export default class StringListDropdownInput extends PureComponent {
  static defaultProps = {
    noResultsMessage: 'Start typing to add a new item',
    placeholder: 'No items specified',
    allowAdditions: true,
    fieldProps: {}
  };

  render() {
    const {
      noResultsMessage, placeholder, allowAdditions,
      name, value, required, schema, disabled, errors, onChange, fieldProps
    } = this.props;

    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <StringListDropdownControl
          allowAdditions={allowAdditions}
          noResultsMessage={noResultsMessage}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </Field>
    );
  }
}
