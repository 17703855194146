import {get, includes, identity, camelCase} from 'lodash';
import ExtendableError from 'es6-error';

export class PermissionError extends ExtendableError {
  constructor(message = 'PermissionError', {title = 'Forbidden', content = 'Not enough permissions'} = {}) {
    super(message);
    Object.assign(this, {message, title, content});
  }

  toMessage() {
    const {title, content} = this;
    return {title, content};
  }
}

export function hasBlueprintPermissions({blueprintPermissions, action = 'read'}) {
  return get(blueprintPermissions, [action], false);
}

export function checkBlueprintPermissions({blueprintPermissions, route, method}) {
  if (!hasBlueprintPermissions({blueprintPermissions, route, method})) throw new PermissionError();
}

export function hasPermissions({permissions, route, method = 'GET'}) {
  const methods = get(permissions, [route, 'methods'], []);
  return includes(methods, method);
}

export function checkPermissions({permissions, route, method}) {
  if (!hasPermissions({permissions, route, method})) throw new PermissionError();
}

export function encodeParam(param) {
  const replacements = {'!': '%21', "'": '%27', '(': '%28', ')': '%29', '~': '%7E'};
  return encodeURIComponent(param).replace(/[!'()~]/g, (char) => replacements[char]);
}

export function interpolateRoute(route, params = {}) {
  return route.replace(/<(.*?)>/g, (_, param) => {
    for (const convert of [identity, camelCase]) {
      const convertedParam = convert(param);
      if (convertedParam in params) return encodeParam(params[convertedParam]);
    }
    throw new TypeError(`Parameter '${param}' for route '${route}' is not specified`);
  });
}
