import {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Label} from 'semantic-ui-react';
import {observable, action, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {map, union, without, identity} from 'lodash';

import DropdownControl from './DropdownControl';
import natsort from '../natsort';

@observer
export default class StringListDropdownControl extends Component {
  static defaultProps = {
    sort: false,
    formatItem: identity,
    knownItems: [],
    value: [],
  };

  static propTypes = {
    sort: PropTypes.bool,
    formatItem: PropTypes.func,
    knownItems: PropTypes.arrayOf(PropTypes.string),
  };

  @observable searchQuery = '';

  addItem = (item) => {
    const newValue = union(this.props.value, [item]);
    if (this.props.sort) newValue.sort(natsort);
    this.props.onChange(newValue);
  };

  removeItem = (item) => {
    this.props.onChange(without(this.props.value, item));
  };

  @action
  onChange = (value = []) => {
    this.searchQuery = '';
    if (this.props.sort) value.sort(natsort);
    this.props.onChange(value);
  };

  @action
  onSearchChange = (searchQuery) => {
    this.searchQuery = searchQuery;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  get options() {
    const {value, knownItems = []} = this.props;
    return map(union(value, knownItems), (item) => ({key: item, text: item, value: item}));
  }

  renderLabel = ({text: item}) => (
    <Label>
      {this.props.formatItem(item)}
      <Icon link name='delete' onClick={() => this.removeItem(item)} aria-label='Delete' role='button' />
    </Label>
  );

  render() {
    const {value, ...props} = this.props;
    delete props.sort;
    delete props.formatItem;
    delete props.knownItems;
    return (
      <DropdownControl
        {...props}
        search
        multiple
        clearable
        closeOnBlur
        closeOnChange
        options={this.options}
        renderLabel={this.renderLabel}
        value={toJS(value)}
        searchQuery={this.searchQuery}
        onSearchChange={(e, {searchQuery}) => this.onSearchChange(searchQuery)}
        onAddItem={(e, {value}) => this.addItem(value)}
        onChange={this.onChange}
      />
    );
  }
}
