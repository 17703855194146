import {isArray, isEmpty, isFunction, isNil, isNumber, transform} from 'lodash';

// eslint-disable-next-line max-len
const ipAddressRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
// eslint-disable-next-line max-len
const hostRegex = /^\b(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])\b$/;
// eslint-disable-next-line max-len
const addressRegex = /^\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b|\b(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])\b$/;
// eslint-disable-next-line max-len
const addressSearchRegex = /^(^$)|(\b[a-zA-Z0-9]\b)|\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b|\b(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.?)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])\b$/;
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{9,}$/;
const macAddressRegex = /^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$/;
const alphaNumericRegex = /^[a-zA-Z0-9._-]+$/;

const matchesRegex = (value, regex) => {
  return !value || regex.test(value);
};

// Custom validators should only trigger for non-empty values and combined with 'isRequired' when needed.
const validators = {
  isRequired: (value = '') => (value === '' || isNil(value)) && 'The field is mandatory',
  isEqualWith: (value = '', valueToCompareWith = '') =>
    value !== valueToCompareWith && 'The fields are not equal',
  isValidIpv4: (value) => !matchesRegex(value, ipAddressRegex) && `Invalid IPv4 address "${value}"`,
  isValidMacAddress: (value) => !matchesRegex(value, macAddressRegex) && `Invalid MAC address "${value}"`,
  isPasswordStrong: (value = '') => !matchesRegex(value, passwordRegex) &&
    'Length should be at least 9 characters. ' +
    'Must contain uppercase letter. ' +
    'Must contain lowercase letter. ' +
    'Must contain digit. Must contain special character',
  isValidHostname: (value, multiple) => !matchesRegex(value, hostRegex) &&
    (multiple ?
      'One or more valid hostnames are expected, e.g. dc1.yourdatacenter.com, dc2.yourdatacenter.com' :
      `Invalid host name "${value}"`
    ),
  areValidHostnames: (value) => {
    return validators.isValidHostname(value, true);
  },
  isValidIpAddressOrHostname: (value, multiple) => !matchesRegex(value, addressRegex) &&
    (multiple ?
      'One or more valid IP addresses and/or hostnames are expected, ' +
      'e.g. 10.1.2.13, 10.1.2.14, dc1.yourdatacenter.com, dc2.yourdatacenter.com' :
      `Invalid IP address or hostname "${value}"`
    ),
  areValidIpAddressesOrHostnames: (value) => {
    return validators.isValidIpAddressOrHostname(value, true);
  },
  isValidIpAddressOrHostnameDomainSearch: (value) => !matchesRegex(value, addressSearchRegex) &&
    'One or more IP addresses and/or hostnames are expected, ' +
    'e.g. 10.1.2.13, 10.1.2.14, dc1.yourdatacenter.com, dc2.yourdatacenter.com',
  isNotEmptyArray: (value) => {
    return isEmpty(value) && 'At least one item is required';
  },
  isAlphaNumericId: (value) => !matchesRegex(value, alphaNumericRegex) &&
      'Invalid ID. Only ASCII alphanumeric characters, underscore, dash and dot are allowed',
  isValidSubnet: (value) => {
    if (validators.isRequired(value)) return;
    const [ip, cidr] = (value || '').split('/');
    return (validators.isValidIpv4(ip) || isNaN(+cidr) || +cidr < 1 || +cidr > 31) &&
      `Invalid subnet address in CIDR notation "${value}"`;
  },
  isArrayOf: (value, ...validators) => {
    if (isArray(value)) {
      const allValues = validators.pop();
      const errors = transform(value, (acc, item) => {
        acc.push(...validateValue(item, validators, allValues));
      }, []);
      if (!isEmpty(errors)) return errors;
    } else {
      return 'Invalid value';
    }
  },
  isInRange: (value, minimum, maximum) => {
    if (validators.isRequired(value)) return;
    if ((isNumber(minimum) && value < minimum) || (isNumber(maximum) && value > maximum)) {
      return `Value is expected to be in range [${minimum}...${maximum}]`;
    }
  }
};

export default validators;

export const validateValue = (value, valueValidators, allValues, path) => {
  return transform(valueValidators, (acc, validator) => {
    // Some validators might require parameterization, the following format must be used in the schema:
    // validators: [['validator'|validatorFn, ...params]]
    let [complexValidator, params] = [validator, []];
    if (isArray(validator)) {
      [complexValidator, ...params] = validator;
    }
    params.push(allValues, path);

    const validationFn = isFunction(complexValidator) ? complexValidator : validators[complexValidator];
    if (isFunction(validationFn)) {
      const error = validationFn(value, ...params);
      if (error) acc.push(error);
    }
  }, []);
};
