import {Message, Icon} from 'semantic-ui-react';
import {map, castArray, compact} from 'lodash';

export default function GenericErrors({errors, icon = 'warning sign', header = 'Error', children}) {
  errors = compact(castArray(errors));
  if (!errors.length) return null;
  return (
    <Message error icon={!!icon}>
      {icon && <Icon name={icon} />}
      <Message.Content>
        {header && <Message.Header>{header}</Message.Header>}
        {errors.length === 1 ?
          <p key='content'>{errors[0]}</p>
        :
          <Message.List>
            {map(errors, (error, index) => <Message.Item key={index}>{error}</Message.Item>)}
          </Message.List>
        }
        {children}
      </Message.Content>
    </Message>
  );
}
