import {Button} from 'semantic-ui-react';
import cx from 'classnames';

import './ButtonLink.less';

const ButtonLink = ({className, onClick, ...props}) => {
  const classNames = cx('link', className);

  const clickHandler = (e, data) => {
    e.stopPropagation();
    onClick?.(e, data);
  };

  return <Button
    className={classNames}
    onClick={clickHandler}
    {...props}
  />;
};

export default ButtonLink;
