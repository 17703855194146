import {isArray, isObject, flatMap, some, includes, noop} from 'lodash';
import HTTPStatus from 'http-status';
import ExtendableError from 'es6-error';
import pLimit from 'p-limit';

const MAX_CONCURRENT_REQUESTS = 5;
const limit = pLimit(MAX_CONCURRENT_REQUESTS);

export async function responseAsJSON(response) {
  const responseBody = await response.text();
  return responseBody.length ? JSON.parse(responseBody) : null;
}

export function responseBodyErrorsAsArray(responseBodyErrors, entityName) {
  function processErrorFragment({errorFragment, prefix}) {
    if (isObject(errorFragment)) {
      if (isArray(errorFragment) && !some(errorFragment, isObject)) {
        return [(prefix ? `${prefix}: ` : '') + errorFragment.join(', ')];
      }
      return flatMap(errorFragment, (value, key) => processErrorFragment({
        errorFragment: value,
        prefix: (prefix || 'object') + (/^\d+$/.test(key) ? `[${key}]` : `.${key}`)
      }));
    }
    return [(prefix ? `${prefix}: ` : '') + String(errorFragment)];
  }
  return processErrorFragment({errorFragment: responseBodyErrors, prefix: entityName});
}

export function buildQueryString(queryParams) {
  const result = new URLSearchParams(queryParams).toString();
  return result.length ? '?' + result : '';
}

export class NetworkError extends ExtendableError {
  constructor(message = 'Network Error', {response = null, responseBody = null} = {}) {
    super(message);
    Object.assign(this, {message, response, responseBody});
  }

  toMessage({titlesByStatus = {}, entityName} = {}) {
    const {response, responseBody} = this;
    let title, content;
    if (response && response.status) {
      title = titlesByStatus[response.status] || HTTPStatus[response.status];
    }
    if (responseBody && responseBody.errors) {
      content = responseBodyErrorsAsArray(responseBody.errors, entityName);
    }
    if (!title) title = 'Network Error';
    if (!content) content = String(this);
    return {title, content};
  }
}

export class AbortError extends Error {
  name = 'AbortError';
  message = 'Aborted';

  toMessage() {
    return {
      title: 'Abort Error',
      content: 'Request was aborted',
    };
  }
}

export function requestProgress(...args) {
  return limit(() => requestProgressRaw(...args));
}

const DONE_STATE = 4;

function requestProgressRaw(url, options) {
  const {
    queryParams = {},
    onProgress = noop,
    body,
    method,
    signal,
  } = options;

  options.headers = {...options.headers, AuthToken: localStorage.getItem('aos_api_token')};

  const urlWithQueryParams = url + buildQueryString(queryParams);
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.withCredentials = true;
    xhr.open(method, urlWithQueryParams);

    function abort() {
      xhr.abort();
    }

    for (const [key, value] of Object.entries(options.headers)) {
      xhr.setRequestHeader(key, value);
    }

    if (signal) {
      signal.addEventListener('abort', abort);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === DONE_STATE) {
          options.signal.removeEventListener('abort', abort);
        }
      };
    }

    xhr.upload.addEventListener('progress', (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      onProgress({
        progress,
        loaded: event.loaded,
        total: event.total,
      });
    }, false);
    xhr.addEventListener('load', () => {
      onProgress(null);
      if (xhr.status >= 200 && xhr.status <= 300) {
        resolve(xhr.response);
      } else {
        reject(new NetworkError(
          `Failed to fetch ${urlWithQueryParams}: ${xhr.status} ${xhr.statusText}`,
          {response: {status: xhr.status}, responseBody: xhr.response}
        ));
      }
    }, false);
    xhr.addEventListener('error', () => {
      onProgress(null);
      reject(new NetworkError(
        `Failed to fetch ${urlWithQueryParams}: ${xhr.status} ${xhr.statusText}`,
        {response: {status: xhr.status}, responseBody: xhr.response}
      ));
    }, false);
    xhr.addEventListener('abort', () => {
      onProgress(null);
      reject(new AbortError());
    });

    xhr.send(body);
  });
}

export default function request(...args) {
  return limit(() => requestRaw(...args));
}

async function requestRaw(url, options = {}) {
  const {
    queryParams = {},
    responseBodyFormatter = responseAsJSON,
    throwOnHttpStatus = [],
    maxRetryCount = 3,
    stopRetryStatuses = [
      400, 401, 403, 404, 409, 412, 413, 422, 429, 500
    ],
    ...fetchOptions
  } = options;
  fetchOptions.credentials = 'include';
  fetchOptions.headers = {...fetchOptions.headers, AuthToken: localStorage.getItem('aos_api_token')};
  let retryCount = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await fetch(url + buildQueryString(queryParams), fetchOptions);
    if (
      includes([...throwOnHttpStatus, ...stopRetryStatuses], response.status) ||
      (!response.ok && retryCount === maxRetryCount)
    ) {
      let responseBody = null;
      try {
        responseBody = await responseAsJSON(response);
      } catch (e) {}
      throw new NetworkError(
        `Failed to fetch ${response.url}: ${response.status} ${response.statusText}`,
        {response, responseBody}
      );
    }
    if (response.ok) {
      return responseBodyFormatter(response);
    }
    retryCount++;
  }
}
