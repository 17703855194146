import copy from 'copy-to-clipboard';
import {FC, useState, useCallback} from 'react';
import {Popup, Icon} from 'semantic-ui-react';

type CopyButtonProps = {
  className?: string;
  textToCopy?: string;
}

export const CopyButton: FC<CopyButtonProps> = ({className, textToCopy}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = useCallback((e) => {
    e.stopPropagation();
    copy(textToCopy!);
    setIsCopied(true);
  }, [textToCopy]);

  if (!textToCopy) return null;

  return (
    <span className={className}>
      <Popup
        on={['hover', 'focus']}
        offset={[-12, 0]}
        content={isCopied ? 'Copied' : 'Copy'}
        trigger={
          <Icon
            name='copy outline'
            onClick={copyText}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                copyText(e);
              }
            }}
            role='button'
            tabIndex={0}
          />
        }
        onClose={() => setIsCopied(false)}
      />
    </span>
  );
};
