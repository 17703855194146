import {Component, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Table, Checkbox, Icon, Popup} from 'semantic-ui-react'; // eslint-disable-line no-restricted-imports
import {get, some, omit, isNil, isFunction, size, transform} from 'lodash';
import cx from 'classnames';

import {onEnterKeyHandler} from '../keyHandlers';

export const DEFAULT_SORTING_DIRECTION = 'asc';

const oppositeSortingDirection = {asc: 'desc', desc: 'asc'};

export default class DataTable extends Component {
  static propTypes = {
    ...Table.propTypes,
    items: PropTypes.array.isRequired,
    allItems: PropTypes.array,
    schema: PropTypes.array.isRequired,
    params: PropTypes.object,
    sorting: PropTypes.object,
    defaultSortingDirection: PropTypes.string,
    updateSorting: PropTypes.func,
    noItemsMessage: PropTypes.string,
    getHeaderCellProps: PropTypes.func,
    getCellProps: PropTypes.func,
    CellComponent: PropTypes.elementType,
    getRowProps: PropTypes.func,
    getItemKey: PropTypes.func,
    renderCustomRow: PropTypes.func,
    customRowKeys: PropTypes.object,
    selectable: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    selection: PropTypes.object,
    updateSelection: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    celled: true,
    noItemsMessage: 'no items',
    sorting: {},
    defaultSortingDirection: DEFAULT_SORTING_DIRECTION,
    selectable: false,
    allowSelectAll: false,
    selection: {},
    customRowKeys: {},
  };

  latestSelectedItemKey = null;

  toggleItem = (itemKey, isSelected, isRange) => {
    const {latestSelectedItemKey, props: {items, getItemKey, selection, updateSelection}} = this;
    let itemKeys = [];

    if (isRange && latestSelectedItemKey !== null) {
      isSelected = selection[latestSelectedItemKey];
      const latestSelectedItemIndex = items.findIndex((item) => getItemKey(item) === latestSelectedItemKey);
      const currentSelectedItemIndex = items.findIndex((item) => getItemKey(item) === itemKey);
      itemKeys = items
        .slice(
          Math.min(latestSelectedItemIndex, currentSelectedItemIndex),
          Math.max(currentSelectedItemIndex, latestSelectedItemIndex) + 1
        )
        .map(getItemKey);
    } else {
      itemKeys = [itemKey];
    }

    updateSelection(isSelected ?
      {...selection, ...transform(itemKeys, (result, key) => {result[key] = true;}, {})}
    :
      omit(selection, itemKeys)
    );

    this.latestSelectedItemKey = itemKey;
  };

  toggleItems = (isSelected) => {
    const {items, getItemKey, selection, updateSelection} = this.props;
    const itemKeys = items.map(getItemKey);

    updateSelection(isSelected ?
      {...selection, ...transform(itemKeys, (result, key) => {result[key] = true;}, {})}
    :
      omit(selection, itemKeys)
    );

    this.latestSelectedItemKey = null;
  };

  selectAllItems = () => {
    const {allItems, getItemKey, updateSelection} = this.props;

    updateSelection(transform(allItems, (result, item) => {result[getItemKey(item)] = true;}, {}));

    this.latestSelectedItemKey = null;
  };

  constructor(props) {
    super(props);

    if (this.props.renderCustomRow && !this.props.getItemKey) {
      throw new Error('getItemKey is not specified for data table with renderCustomRow');
    }

    if (this.props.selectable && !this.props.getItemKey) {
      throw new Error('getItemKey is not specified for selectable data table');
    }

    if (this.props.allowSelectAll && !this.props.allItems) {
      throw new Error('allItems is not specified for Select All control support');
    }
  }

  updateSortingForColumn = (columnName, addSorting) => {
    const {sorting, updateSorting, defaultSortingDirection} = this.props;
    const newSortingDirection = sorting[columnName] ?
      oppositeSortingDirection[sorting[columnName]]
    :
      defaultSortingDirection;
    const newSorting = addSorting ? omit(sorting, columnName) : {};
    newSorting[columnName] = newSortingDirection;
    updateSorting(newSorting);
  };

  render() {
    const {
      items, allItems, schema, params, sorting, noItemsMessage, className,
      selectable, selection, allowSelectAll,
      renderCustomRow, customRowKeys,
      getHeaderCellProps, getCellProps, CellComponent, getRowProps, getItemKey,
      footer,
      ...tableProps
    } = this.props;
    delete tableProps.defaultSortingDirection;
    delete tableProps.updateSorting;
    delete tableProps.updateSelection;
    const tableSortable = isNil(this.props.sortable) ? some(schema, 'sortable') : this.props.sortable;

    const allItemsSelected = !!items.length && !!getItemKey &&
      items.every((item) => selection[getItemKey(item)]);
    const someItemsSelected = !!items.length && !!getItemKey && !allItemsSelected &&
      items.some((item) => selection[getItemKey(item)]);

    return (
      <Table
        className={cx('data-table', className)}
        role='table'
        sortable={tableSortable}
        {...tableProps}
      >
        <Table.Header>
          <Table.Row role='row'>
            {selectable &&
              <Table.HeaderCell
                key='checkbox'
                role='columnheader'
                className='checkbox'
                onClick={() => this.toggleItems(!allItemsSelected)}
                disabled={!items.length}
              >
                <Checkbox
                  checked={allItemsSelected}
                  indeterminate={someItemsSelected}
                  disabled={!items.length}
                  aria-label={items.length ? 'Select' : null}
                />
                <small>{`${size(selection)} selected`}</small>
              </Table.HeaderCell>
            }
            {schema.map(({name, label, description, sortable}) => {
              const headerCellProps = getHeaderCellProps ? getHeaderCellProps({name}) : null;
              if (!tableSortable) sortable = false;
              return (
                <Table.HeaderCell
                  key={name}
                  role='columnheader'
                  tabIndex={sortable ? 0 : undefined}
                  sorted={{asc: 'ascending', desc: 'descending'}[sorting[name]] || null}
                  disabled={!sortable}
                  onClick={sortable ? (e) => this.updateSortingForColumn(name, e.shiftKey) : null}
                  onKeyDown={sortable ? onEnterKeyHandler(this.updateSortingForColumn, name) : null}
                  {...headerCellProps}
                >
                  {label}
                  {description &&
                    <Popup
                      trigger={<sup><Icon name='help circle' /></sup>}
                      content={description}
                      position='top left'
                      offset={[-14, 0]}
                    />
                  }
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {selectable && allowSelectAll && allItemsSelected && allItems.length !== size(selection) &&
            <Table.Row className='select-all-items' warning textAlign='center'>
              <Table.Cell colSpan={schema.length + 1}>
                <small>
                  {'All items on this page are selected. '}
                  <button className='dotted-underline-button' onClick={this.selectAllItems}>
                    {`Select all ${allItems.length} items`}
                  </button>
                </small>
              </Table.Cell>
            </Table.Row>
          }
          {items.length ?
            items.map((item, index) => {
              const args = {item, items, params, index};
              const rowProps = getRowProps ? getRowProps(args) : null;
              const itemKey = getItemKey ? getItemKey(item) : null;
              if (renderCustomRow && customRowKeys[itemKey]) return renderCustomRow(args);
              const selected = !!selection[itemKey];
              return (
                <Table.Row
                  key={itemKey ?? index}
                  role='row'
                  warning={selected}
                  {...rowProps}
                >
                  {selectable &&
                    <Table.Cell
                      key='checkbox'
                      className='checkbox'
                      role='cell'
                    >
                      <Checkbox
                        checked={selected}
                        onClick={(e) => this.toggleItem(itemKey, !selected, e.shiftKey)}
                        aria-label='Select'
                      />
                    </Table.Cell>
                  }
                  <DataTableRowFragment
                    schema={schema}
                    item={item}
                    items={items}
                    index={index}
                    params={params}
                    getCellProps={getCellProps}
                    CellComponent={CellComponent}
                  />
                </Table.Row>
              );
            })
          :
            <Table.Row key='no-items'>
              <Table.Cell colSpan={schema.length} textAlign='center'>
                {noItemsMessage}
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
        {footer}
      </Table>
    );
  }
}

export class DataTableRowFragment extends PureComponent {
  static propTypes = {
    schema: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    items: PropTypes.array,
    index: PropTypes.number,
    params: PropTypes.object,
    getCellProps: PropTypes.func,
  };

  static defaultProps = {
    CellComponent: Table.Cell
  };

  render() {
    const {schema, item, items, index, params, getCellProps, CellComponent} = this.props;
    const args = {item, items, index, params};
    return schema.map(({name, value: valueGetter, formatter: valueFormatter}) => {
      const value = isFunction(valueGetter) ? valueGetter({name, ...args}) : get(item, valueGetter, null);
      const formattedValue = isFunction(valueFormatter) ? valueFormatter({name, value, ...args}) : value;
      const cellProps = getCellProps ? getCellProps({name, value, ...args}) : null;
      return <CellComponent key={name} {...cellProps}>{formattedValue}</CellComponent>;
    });
  }
}
