import {FC} from 'react';

import {MainSidebarMenuItem} from '../MainSidebar';
import {useGlobalHelp} from './GlobalHelpContext';

export const GlobalHelpSidebarButton: FC = () => {
  const {showHelpForPage} = useGlobalHelp();
  return (
    <MainSidebarMenuItem
      iconClass='question circle outline'
      label='Help'
      onClick={() => showHelpForPage(null)}
      order={20}
    />
  );
};
