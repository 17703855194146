import {Component, createRef, useEffect} from 'react';
import {HashRouter, Link} from 'react-router-dom';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Message, Transition, Icon} from 'semantic-ui-react';
import {useResizeDetector} from 'react-resize-detector';

import {uniqueId, pullAllWith, isMatch, castArray} from 'lodash';

import './Notifier.less';

@observer
export default class Notifier extends Component {
  static defaultProps = {
    animation: 'fade left',
    animationDuration: 300,
    hideNotificationDelay: 5000,
    notificationSpacing: 15,
  };

  @observable.shallow notifications = [];
  @observable notificationHeights = {};

  containerRef = createRef();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  showNotification({
    title, message, status = 'success', stayOnScreen = false,
    resourceLabel = null, resourceHref = null,
  }) {
    const id = uniqueId('notification');
    const notification = {id, title, message, status, resourceLabel, resourceHref};
    this.notifications.push(notification);
    if (!stayOnScreen) {
      setTimeout(() => this.hideNotification(id), this.props.hideNotificationDelay);
    }
    return id;
  }

  @action
  hideNotification = (id) => {
    pullAllWith(this.notifications, [{id}], isMatch);
    delete this.notificationHeights[id];
  };

  @action
  updateNotificationHeight = (id, height) => {
    this.notificationHeights[id] = height;
  };

  render() {
    const {
      notifications, notificationHeights, updateNotificationHeight, hideNotification,
      props: {animation, animationDuration, notificationSpacing},
    } = this;
    let currentTop = 0;
    return (
      <div className='notifier'>
        <Transition.Group animation={animation} duration={animationDuration}>
          {notifications.map((notification) => {
            const renderedNotification = (
              <NotificationContainer
                key={notification.id}
                currentTop={currentTop}
                notification={notification}
                updateNotificationHeight={updateNotificationHeight}
                hideNotification={hideNotification}
              />
            );
            currentTop += notificationSpacing + (notificationHeights[notification.id] || 0);
            return renderedNotification;
          })}
        </Transition.Group>
      </div>
    );
  }
}

const NotificationContainer = ({currentTop, notification, hideNotification, updateNotificationHeight}) => {
  const {height, ref} = useResizeDetector();
  useEffect(() => {
    updateNotificationHeight(notification.id, height);
  }, [height, notification, updateNotificationHeight]);
  return (
    <div ref={ref} style={{top: currentTop}}>
      <Notification
        notification={notification}
        hideNotification={hideNotification}
      />
    </div>
  );
};

export class Notification extends Component {
  static defaultProps = {
    iconsByStatus: {
      success: 'check circle',
      error: 'warning circle',
      warning: 'warning circle',
    }
  };

  render() {
    const {
      hideNotification,
      notification: {id, status, title, message, resourceLabel, resourceHref},
      iconsByStatus, icon = iconsByStatus[status]
    } = this.props;
    const messages = message ? castArray(message) : [];
    return (
      <Message
        key={id}
        className='notification'
        icon={!!icon}
        onDismiss={() => hideNotification(id)}
        {...{[status]: true}}
      >
        {icon && <Icon name={icon} />}
        <Message.Content>
          {title && <Message.Header>{title}</Message.Header>}
          {resourceLabel &&
            <div className='resource-name'>
              {resourceHref ?
                <HashRouter><Link reloadDocument to={resourceHref}>{resourceLabel}</Link></HashRouter>
              :
                <span>{resourceLabel}</span>
              }
            </div>
          }
          {messages.length === 0 ?
            null
          : messages.length === 1 ?
            <div>{messages[0]}</div>
          :
            <Message.List>
              {messages.map((message, index) => <Message.Item key={index}>{message}</Message.Item>)}
            </Message.List>
          }
        </Message.Content>
      </Message>
    );
  }
}
