import {indexOf, isPlainObject, keys, pick, sortBy} from 'lodash';
import JSONPretty from 'react-json-pretty';

import './FormattedJSON.less';

export const orderedKeysReplacer = (priorityKeys = []) => (key, value) => {
  if (!isPlainObject(value)) return value;
  const orderedKeys = sortBy(keys(value), [
    (keyName) => (indexOf(priorityKeys, keyName) + 1) || Number.MAX_VALUE,
    (keyName) => keyName
  ]);
  return pick(value, orderedKeys);
};

export default function FormattedJSON({...props}) {
  return <JSONPretty {...props} />;
}
