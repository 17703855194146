import {Component} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {isNil} from 'lodash';

import generateOptionsFromSchema from '../generateOptionsFromSchema';
import Field from './Field';
import BooleanInput from './BooleanInput';
import DropdownInput from './DropdownInput';

@observer
export default class ValueInput extends Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    renderers: PropTypes.array,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    errors: PropTypes.array,
    fieldProps: PropTypes.object
  };

  static defaultProps = {
    renderers: [],
    required: false,
    disabled: false,
    hidden: false,
    errors: [],
  };

  renderAsComponent({as: Component, ...props}) {
    return Component ? <Component {...props} /> : null;
  }

  renderBooleanInput(props) {
    return props.schema.type === 'boolean' ? <BooleanInput {...props} /> : null;
  }

  renderDropdownInput(props) {
    let options;
    let multiple = false;
    if (
      (options = generateOptionsFromSchema(props.schema)) ||
      props.schema.type === 'array' &&
      props.schema.items &&
      (options = generateOptionsFromSchema(props.schema.items)) && (multiple = true)
    ) {
      return <DropdownInput {...props} multiple={multiple} options={options} />;
    }
    return null;
  }

  renderTextInput({
    name, value = '', placeholder, schema, required, disabled, errors, fieldProps, onChange, icon
  }) {
    if (
      schema.type === 'string' ||
      schema.type === undefined // workaround for lollipop bug - no type means string
    ) {
      return (
        <Field
          label={schema?.title ?? name}
          description={schema?.description}
          required={required}
          disabled={disabled}
          errors={errors}
          {...fieldProps}
        >
          <Input
            type='text'
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            icon={icon}
          />
        </Field>
      );
    }
    return null;
  }

  renderNumberInput({
    name, value, placeholder, schema, required, disabled, errors, fieldProps, onChange, icon
  }) {
    if (schema.type === 'integer' || schema.type === 'number') {
      return (
        <Field
          label={schema?.title ?? name}
          description={schema?.description}
          required={required}
          disabled={disabled}
          errors={errors}
          {...fieldProps}
        >
          <Input
            type='number'
            step={schema.type === 'integer' ? 1 : 'any'}
            value={isNil(value) ? '' : value}
            placeholder={placeholder}
            min={schema.minimum}
            max={schema.maximum}
            onChange={(e) => onChange(e.target.value.length ? Number(e.target.value) : null)}
            icon={icon}
          />
        </Field>
      );
    }
    return null;
  }

  renderValueInput(props) {
    let result = null;
    for (const renderingMethod of [
      'renderAsComponent',
      ...this.props.renderers,
      'renderBooleanInput',
      'renderDropdownInput',
      'renderTextInput',
      'renderNumberInput',
    ]) {
      result = typeof renderingMethod === 'string' ?
        this[renderingMethod](props) :
        renderingMethod.call(this, props);
      if (result !== null) break;
    }
    return result;
  }

  render() {
    const {hidden, ...props} = this.props;
    if (hidden) return null;
    delete props.renderers;
    return this.renderValueInput(props);
  }
}
