import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {without} from 'lodash';

const omittedProps = ['name', 'prototype', 'length', 'contextType'];

export const withRouter = (Component) => {
  // Mix router hooks values into props
  const Wrapper = (props) => (
    <Component {...{location: useLocation(), navigate: useNavigate(), params: useParams(), ...props}} />
  );

  // Bubble static properties up
  without(Object.getOwnPropertyNames(Component), ...omittedProps).forEach((name) => {
    Wrapper[name] = Component[name];
  });

  return Wrapper;
};
