import {Component} from 'react';
import PropTypes from 'prop-types';
import {Popup} from 'semantic-ui-react';

import {formatDateAsTimeFromNow, formatDateAsLocalDateTimeMS} from '../formatters/formatDate';

export default class DateFromNow extends Component {
  static propTypes = {
    date: PropTypes.instanceOf(Date)
  };

  render() {
    const {date, popupPosition} = this.props;
    return (date instanceof Date && !isNaN(date.getTime())) ? (
      <Popup
        position={popupPosition}
        trigger={
          <span>{formatDateAsTimeFromNow(date)}</span>
        }
      >
        {formatDateAsLocalDateTimeMS(date)}
      </Popup>
    ) : null;
  }
}
