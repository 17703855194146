import {Component, isValidElement} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {find, isNil, isString, isPlainObject} from 'lodash';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import PropTypes from 'prop-types';

@observer
export default class DropdownControl extends Component {
  static propTypes = {
    selectedValueLabel: PropTypes.string,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get text() {
    const {text, value, multiple, selectedValueLabel, options} = this.props;
    if (multiple) return undefined;
    if (isString(text)) return text;
    if (isNil(selectedValueLabel)) return undefined;
    const option = find(options, {value});
    return option ? `${selectedValueLabel}${option.text}` : undefined;
  }

  @computed
  get searchInput() {
    const {search, searchInput, placeholder} = this.props;
    if (!search) return undefined;
    const searchInputProps = {};
    if (this.props['aria-label']) {
      searchInputProps['aria-label'] = this.props['aria-label'];
    } else if (this.props['aria-labelledby']) {
      searchInputProps['aria-labelledby'] = this.props['aria-labelledby'];
    } else if (placeholder) {
      searchInputProps['aria-label'] = placeholder;
    }
    return isNil(searchInput) ? searchInputProps :
      isValidElement(searchInput) ? searchInput :
      isPlainObject(searchInput) ? {...searchInput, ...searchInputProps} :
      searchInput;
  }

  render() {
    const {text, searchInput, props: {onChange, ...props}} = this;
    delete props.selectedValueLabel;
    return (
      <Dropdown
        selection={!props.inline}
        selectOnBlur={false}
        onChange={onChange ? (e, {value}) => onChange(value) : undefined}
        {...props}
        aria-label={
          props.search ? undefined :
          props['aria-label'] ? props['aria-label'] :
          text ? text :
          props.placeholder
        }
        aria-labelledby={
          !props.search && props['aria-labelledby']
            ? props['aria-labelledby']
            : undefined
        }
        searchInput={searchInput}
        text={text}
      />
    );
  }
}
