import {FC, ReactNode, useCallback, useState} from 'react';
import {Button, Popup} from 'semantic-ui-react';

import {onEnterKeyHandler} from '../../keyHandlers';
import {useGlobalHelp} from './GlobalHelpContext';

import './GlobalHelpButton.less';

type GlobalHelpButtonProps = {
  helpPageId: string | null;
  tooltipContent?: string | ReactNode;
}

export const GlobalHelpButton: FC<GlobalHelpButtonProps> = ({
  helpPageId,
  tooltipContent
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {showHelpForPage, topicTooltips} = useGlobalHelp();
  const showHelp = useCallback(
    () => showHelpForPage(helpPageId),
    [helpPageId, showHelpForPage]
  );
  const button = <Button
    basic
    circular
    aria-label='Help'
    className='global-help-button'
    icon='help'
    onClick={showHelp}
    size='mini'
  />;
  tooltipContent ??= helpPageId ? topicTooltips?.[helpPageId] : null;
  return tooltipContent ?
    <Popup
      content={
        <div className='global-help-tooltip-content'>
          <div>
            {tooltipContent}
          </div>
          <div
            className='more-button'
            onClick={showHelp}
            onKeyDown={onEnterKeyHandler(showHelp)}
            role='button'
            tabIndex={0}
          >
            {'More...'}
          </div>
        </div>
      }
      hoverable
      inverted={false}
      offset={[5, 0]}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      wide='very'
      trigger={button}
    /> : button;
};
