import {clamp} from 'lodash';

export function formatBytesPerSecond(value, digits = 2) {
  const speedUnits = ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps'];
  return formatBytes(value, speedUnits, digits, 1000);
}

export function formatBytes(value, units = ['B', 'KB', 'MB', 'GB', 'TB'], digits = 2, base = 1000) {
  const bps = Number(value);
  const order = clamp(Math.floor(Math.log(bps) / Math.log(base)), 0, units.length - 1);
  const strValue = (bps / (base ** order)).toFixed(digits);
  return `${strValue} ${units[order]}`;
}
