export const COMBINE_GRAPHS_MODE = {
  NONE: 'none',
  LINEAR: 'linear',
  STACKED: 'stacked',
};
export const DEFAULT_COMBINE_GRAPHS_MODE = COMBINE_GRAPHS_MODE.NONE;

export const CHART_VALUE_CIRCLE_RADIUS = 4;
export const CHART_ELEMENTS_STROKE_WIDTH = 2;
export const CHART_RANGE_HOVER_RECTANGLE_HEIGHT = 2;

export const LINE_MODE = {
  LINES: 'lines',
  MARKERS: 'markers',
  LINES_MARKERS: 'lines+markers',
};
