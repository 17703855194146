import {useQueries} from '@tanstack/react-query';
import {forEach} from 'lodash';
import {FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useRef, useState} from 'react';

import {GlobalHelpModal} from './GlobalHelpModal';
import {queryKeys} from './queryKeys';
import {HelpSectionType, WindowLocation, isHelpPageType} from './types';

export type GlobalHelpContextValue = {
  basePath: string;
  canGoBack: boolean;
  canGoForward: boolean;
  goHistoryBack: () => void;
  goHistoryForward: () => void;
  isOpen: boolean;
  localImagePath: string;
  orderedTopicUrls: string[];
  setIsOpen: (isOpen: boolean) => void;
  navigate: (url: string | null) => void;
  preferences?: WindowLocation;
  setPreferences?: (preferences: WindowLocation) => void;
  showHelpForPage: (helpPageId: string | null) => void;
  topicTooltips?: Record<string, string>;
  topicUrlById: Record<string, string> | null,
  topics?: HelpSectionType[];
  url: string | null;
};

const GlobalHelpContext = createContext<GlobalHelpContextValue | null>(null);

function getHelpMapping(helpMapContent: string): Record<string, string> {
  const parser = new DOMParser();
  const xml = parser.parseFromString(helpMapContent, 'text/xml');
  const aliasMapping = {} as Record<string, string>;
  forEach(xml.querySelectorAll('Map'), (node) => {
    const linkName: string | null = node.getAttribute('Name');
    const linkNameRes = linkName ? linkName : 'Null';
    const target = node.getAttribute('Link');
    const targetRes = target ? target : 'Null';
    aliasMapping[linkNameRes] = targetRes;
  });
  return aliasMapping;
}

export const useGlobalHelp = (): GlobalHelpContextValue => {
  const contextValue = useContext(GlobalHelpContext);
  if (!contextValue) {
    throw new Error('Wrap with HelpProvider');
  }
  return contextValue;
};

export type GlobalHelpProviderProps = {
  basePath: string;
  helpMappipngUrl: string;
  helpTopicsUrl: string;
  localImagePath: string;
  preferences?: WindowLocation | null;
  setPreferences?: (preferences: WindowLocation) => void;
  topicTooltips: string;
}

export const GlobalHelpProvider: FC<PropsWithChildren<GlobalHelpProviderProps>> = ({
  children,
  basePath,
  helpMappipngUrl,
  helpTopicsUrl,
  localImagePath,
  preferences,
  setPreferences,
  topicTooltips
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const {current: history} = useRef<(string | null)[]>([]);
  const [historyPos, setHistoryPos] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [helpMapQuery, helpTopicsQuery, topicTooltipsQuery] = useQueries({
    queries: [
      {
        queryKey: queryKeys.helpMappipng(),
        queryFn: ({signal}) => fetch(helpMappipngUrl, {signal}).then((r) => r.text()),
        gcTime: Infinity,
        staleTime: Infinity
      },
      {
        queryKey: queryKeys.helpTopics(),
        queryFn: ({signal}) => fetch(helpTopicsUrl, {signal}).then((r) => r.json()),
        gcTime: Infinity,
        staleTime: Infinity
      },
      {
        queryKey: queryKeys.topicTooltips(),
        queryFn: ({signal}) => fetch(topicTooltips, {signal}).then((r) => r.json()),
        gcTime: Infinity,
        staleTime: Infinity
      }
    ]
  });

  const topicUrlById = useMemo(
    () => helpMapQuery.data ? getHelpMapping(helpMapQuery.data) : null,
    [helpMapQuery]
  );

  const navigate = useCallback((newUrl: string | null) => {
    if (newUrl === url) return;
    setUrl(newUrl);
    if (historyPos < history.length - 1) {
      history.splice(historyPos + 1, history.length - 1 - historyPos);
    }

    history.push(newUrl);
    setHistoryPos(historyPos + 1);
  }, [history, historyPos, url]);

  const goHistoryBack = useCallback(() => {
    setUrl(history[historyPos - 1] ?? null);
    setHistoryPos(historyPos - 1);
  }, [history, historyPos]);

  const goHistoryForward = useCallback(() => {
    setUrl(history[historyPos + 1] ?? null);
    setHistoryPos(historyPos + 1);
  }, [history, historyPos]);

  const showHelpForPage = useCallback((helpPageId: string | null) => {
    if (!helpPageId || !topicUrlById) {
      navigate(null);
      setIsOpen(true);
      return;
    }
    navigate(topicUrlById?.[helpPageId]);
    setIsOpen(true);
  }, [navigate, topicUrlById]);

  const topics = helpTopicsQuery.data?.toc.children as HelpSectionType[];
  const orderedTopicUrls = useMemo(
    () => {
      const result: string[] = [];
      function iterate(section: HelpSectionType) {
        forEach(section.children, (child) => {
          if (isHelpPageType(child)) {
            result.push(child.url);
          } else {
            iterate(child);
          }
        });
      }
      forEach(topics, iterate);
      return result;
    },
    [topics]
  );

  return (
    <GlobalHelpContext.Provider
      value={{
        basePath,
        canGoBack: historyPos > 0,
        canGoForward: historyPos < history.length - 1,
        goHistoryBack,
        goHistoryForward,
        isOpen,
        localImagePath,
        navigate,
        orderedTopicUrls,
        preferences: preferences || undefined,
        setIsOpen,
        setPreferences,
        showHelpForPage,
        topicUrlById,
        topics,
        topicTooltips: topicTooltipsQuery.data,
        url
      }}
    >
      <GlobalHelpModal />
      {children}
    </GlobalHelpContext.Provider>
  );
};

