import React from 'react';

type UseTrackOutsideClickArgs = {
  enabled: boolean;
  onOutsideClick: () => void;
  ref: React.RefObject<HTMLElement>;
}

export const useTrackOutsideClick = ({enabled, onOutsideClick, ref}: UseTrackOutsideClickArgs): void => {
  React.useEffect(() => {
    if (enabled) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          onOutsideClick();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [enabled, onOutsideClick, ref]);
};
