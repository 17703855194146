import {useCallback, useEffect, useState} from 'react';
import {clone, concat, forEach, isArray, isEmpty, isFunction, isPlainObject, isString, isUndefined, merge,
  mergeWith, omit, transform, set, unset, isEqual, get} from 'lodash';
import {set as fpSet} from 'lodash/fp';
import {observer} from 'mobx-react';
import {Form} from 'semantic-ui-react';

import RichFormFragment, {OWN_ERRORS_KEY} from './RichFormFragment';
import {validateValue} from '../../validators';
import {getSchemaDefaults} from '../../formSchema';

import './JsonConfigurator.less';

const JsonConfigurator = ({schema, values, onChange, reportErrors, formState, setFormState, externalErrors}) => {
  const {errors, onDeepChange, state, setState} = useJsonConfigurator({schema, values, onChange, reportErrors,
    formState, setFormState, externalErrors});
  return (
    <Form className='json-configurator'>
      <RichFormFragment
        schema={schema}
        values={clone(values)}
        onChange={onDeepChange}
        errors={errors}
        formState={state}
        setFormState={setState}
      />
    </Form>
  );
};

export default observer(JsonConfigurator);

// Temporary solution to parse external errors format received from the backend
const deepArrayMerger = (value1, value2) => {
  if (isString(value1) || isString(value2)) {
    const [str, obj] = isString(value1) ? [value1, value2] : [value2, value1];
    if (isString(obj)) {
      return [str, obj];
    } else if (isArray(obj)) {
      return concat(str, obj);
    } else if (isPlainObject(obj)) {
      return {...obj, [OWN_ERRORS_KEY]: concat(obj?.[OWN_ERRORS_KEY] ?? [], str)};
    }
  }
};

const useJsonConfigurator = ({schema, values, onChange, reportErrors, formState, setFormState, externalErrors}) => {
  const [errors, setErrors] = useState({});
  const [localState, setLocalState] = useState({});

  // If form state needed to be preserved (e.g. between tabs switching), its state might be preserved
  // via provided formState & setFormState props. Otherwise local state will be used.
  const [state, setState] = setFormState ? [formState, setFormState] : [localState, setLocalState];

  // Adding defaults to the value on the first render and initial state generation
  useEffect(
    () => {
      const updatedValues = merge(getSchemaDefaults(schema), values);
      const updatedState = calculateFormState(updatedValues, schema, state, [], updatedValues, []);
      if (state !== updatedState) setState(updatedState);
      onChange(updatedValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // OnChange wrapper for the deeply nested properties
  const onDeepChange = useCallback(
    (path, value, removePath) => {
      const updatedValues = isUndefined(value) ?
        // If no value provided - the key must be omitted.
        // NB: path must be passed to omit as a string in order to properly process nested arrays
        omit(values, path.join('.')) :
        // Otherwise set value on the new path (and remove on the old path if provided)
        fpSet(path, value, removePath ? omit(values, removePath) : values);

      // Whenever the value changes, run all the triggers to update state
      const updatedState = calculateFormState(updatedValues, schema, state, [], updatedValues, path);
      if (state !== updatedState) setState(updatedState);

      onChange(updatedValues, path, value);
    },
    [values, schema, state, setState, onChange]
  );

  // Whenever values or schema chahges, recalculate the errors
  useEffect(() => {
    const validationErrors = validate(values, schema, state, values, []);
    setErrors(validationErrors);
    if (isFunction(reportErrors)) {
      reportErrors(validationErrors);
    }
  }, [values, schema, reportErrors, state]);

  // Combines internal validation errors with the server-side errors
  const errorsCombined = mergeWith({}, errors, externalErrors, deepArrayMerger);

  return {errors: errorsCombined, onDeepChange, state, setState};
};

const REQUIRED_VALUE = 'Value is required!';

const validate = (values, schema = {}, formState, allValues = values, path = []) => {
  return transform(
    schema,
    (acc, {name, disabled, required, schema: {type, itemSchema} = {}, validators: valueValidators = []}) => {
      const itemState = formState?.[name];

      // Disabled or hidden parameters must not be validated
      if (disabled || itemState?.hidden) return;

      const value = values?.[name];

      let result;
      if (itemSchema) {
        if (isEmpty(value)) {
          // Check if required ...
          // * Array has at least one element
          // * Nested object is not empty
          if (required) result = REQUIRED_VALUE;
        } else if (type === 'array') {
          // Array of schemed items - check all existing items for their errors
          result = transform(value, (arrayErrors, itemValue, index) => {
            const itemErrors = validate(itemValue, itemSchema, itemState, allValues, [...path, name, index]);
            if (!isEmpty(itemErrors)) {
              arrayErrors[index] = itemErrors;
            }
          }, {});
        } else {
          // Nested form fragment
          result = validate(value, itemSchema, itemState, allValues, [...path, name]);
        }
      } else {
        // Single parameter
        result = validateValue(value, required ? ['isRequired', ...valueValidators] : valueValidators, allValues, path);
      }
      if (!isEmpty(result)) acc[name] = result;
    },
    {}
  );
};

// Evaluating all form values, execute triggers where necessary in order
// to actualize the form state given
const calculateFormState = (values, schema, formState, path, updatedValues, changePath) => {
  let newState = formState;
  forEach(
    schema,
    ({name, triggers, schema: {type, itemSchema} = {}}) => {
      const value = values?.[name];
      const currentPath = [...path, name];
      if (itemSchema) {
        if (type === 'array') {
          // Array of schemed items
          newState = fpSet(currentPath, get(newState, currentPath, {}), newState);

          // Array's own triggers
          const isOwnValueChanged = isEqual(currentPath, changePath);
          newState = processTriggers(triggers, value, newState, currentPath, updatedValues, isOwnValueChanged);

          // Array' items triggers
          forEach(value, (itemValue, index) => {
            newState = calculateFormState(
              itemValue, itemSchema, newState, [...currentPath, index], updatedValues, changePath
            );
          });
        } else {
          // Nested form fragment
          newState = calculateFormState(value, itemSchema, newState, currentPath, updatedValues, changePath);
        }
      } else {
        // Single parameter
        const isOwnValueChanged = isEqual(currentPath, changePath);
        newState = processTriggers(triggers, value, newState, path, updatedValues, isOwnValueChanged);
      }
    }
  );
  return newState;
};

// updatedValues is mutable parameter to process triggered value changes
const processTriggers = (triggers, value, formState, path, updatedValues, isOwnValueChanged) => {
  let newState = formState;

  forEach(triggers, ({action, target, relativePath, compareValue, value: triggerValue, conditionFn}) => {
    if (!target) return;
    const customComparator = isFunction(conditionFn);
    const defaultCompare = isUndefined(compareValue) && !customComparator;
    const isMatch = customComparator ?
      conditionFn({value, path, values: updatedValues}) :
      value === compareValue;
    const targetPath = [...(relativePath ? path : []), ...target];

    switch (action) {
    case 'enable':
      newState = fpSet([...targetPath, 'disabled'], defaultCompare ? !value : !isMatch, newState);
      break;
    case 'disable':
      newState = fpSet([...targetPath, 'disabled'], defaultCompare ? !!value : isMatch, newState);
      break;
    case 'show':
      // eslint-disable-next-line no-case-declarations
      const isShown = defaultCompare ? !!value : isMatch;
      newState = fpSet([...targetPath, 'hidden'], !isShown, newState);
      if (!isShown) unset(updatedValues, targetPath);
      break;
    case 'hide':
      // eslint-disable-next-line no-case-declarations
      const isHidden = defaultCompare ? !!value : isMatch;
      newState = fpSet([...targetPath, 'hidden'], isHidden, newState);
      if (isHidden) unset(updatedValues, targetPath);
      break;
    case 'set':
      // Only trigger is initiator value changed
      if (isOwnValueChanged && (defaultCompare || isMatch)) {
        set(updatedValues, targetPath, triggerValue);
      }
      break;
    }
  });
  return newState;
};
