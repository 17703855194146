import {isArray, isString} from 'lodash';

export function withPrefix(values, prefix) {
  if (!isString(prefix)) {
    return values;
  }
  if (isString(values)) {
    return `${prefix}${values}`;
  }
  if (isArray(values)) {
    return values.map((value) => `${prefix}${value}`);
  }
  return values;
}
