export const onEnterKeyHandler = (handler, ...args) => (e) => {
  if (e.key === 'Enter') {
    handler(...args);
  }
};

export const stopEnterPropagation = (e) => {
  if (e.key === 'Enter') {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const keyPressClick = (handler, ...args) => (e) => {
  if ([' ', 'Enter'].includes(e.key)) {
    handler(...args, e);
  }
};

export const preventClickThrough = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export const isExtenderPressed = (event) => {
  return event?.shiftKey || event?.altKey || event?.ctrlKey || event?.metaKey;
};
