import cx from 'classnames';
import {filter, isEmpty, map, sortBy} from 'lodash';
import React from 'react';

import {Hoverable} from './Hoverable';
import {Link} from './Link';
import {MenuItem, useMainSidebar} from './MainSidebarProvider';

import './SubmenuItem.less';

type SubmenuItemProps = {
  className?: string;
  menuItem: MenuItem;
};

export const SubmenuItem: React.FC<SubmenuItemProps> = ({
  className,
  menuItem
}) => {
  const {menuItems} = useMainSidebar();
  const children = React.useMemo(
    () => sortBy(filter(menuItems, {parent: menuItem.menuKey}), 'order'),
    [menuItem.menuKey, menuItems]
  );
  const isHeader = !isEmpty(children);
  return isHeader ?
    <>
      <div className='main-sidebar-header'>
        <div
          children={menuItem.label}
          className={cx('submenu-item', 'header', className)}
        />
      </div>
      {map(children, (child) =>
        <SubmenuItem
          key={child.menuKey}
          className={cx('secondary', className)}
          menuItem={child}
        />
      )}
      <div className='submenu-space' />
    </> :
    <Hoverable highlighted={menuItem.active}>
      <Link
        className={cx('submenu-item', className)}
        menuItem={menuItem}
      >
        {menuItem.label}
      </Link>
    </Hoverable>;
};
