import cx from 'classnames';
import {map} from 'lodash';
import React from 'react';

import {MenuItem} from './MainSidebarProvider';
import './SubmenuContent.less';
import {SubmenuItem} from './SubmenuItem';
import {useStateContext} from './StateContext';

type SubmenuContentProps = {
  menuItems : MenuItem[];
}

export const SubmenuContent: React.FC<SubmenuContentProps> = ({menuItems}) => {
  const {size} = useStateContext();
  const withMarkers = size === 'expanded';
  return (
    <div className={cx('submenu-content', {'with-markers': withMarkers})} role='menu'>
      {map(menuItems, (menuItem) =>
        <SubmenuItem key={menuItem.menuKey} menuItem={menuItem} />
      )}
    </div>
  );
};
