import {useCallback, useRef} from 'react';
import {isNull} from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ChartTimestampSelector.less';

const HANDLER_TOP_OFFSET = -10;

const ChartTimestampSelector = ({height, width, linePosition = null, onSelect, children}) => {
  const chartClickRectRef = useRef(null);

  const onClick = useCallback((e) => {
    const rectEl = chartClickRectRef.current.getBoundingClientRect();
    const x = e.clientX - rectEl.left;
    onSelect(x);
  }, [onSelect]);

  return (
    <g className='chart-timestamp-selector' onClick={onClick}>
      <rect
        ref={chartClickRectRef}
        x={0}
        y={0}
        width={width}
        height={height}
      />
      {children}
      <line
        className={cx('timestamp-selection-line', {hidden: isNull(linePosition)})}
        y1={HANDLER_TOP_OFFSET}
        y2={height}
        x1={linePosition}
        x2={linePosition}
      />
    </g>
  );
};

ChartTimestampSelector.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  linePosition: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

export default ChartTimestampSelector;
