import {useState} from 'react';
import {Input, Icon} from 'semantic-ui-react';

import Field from './Field';

const PasswordInput = ({
  name, value = '', onChange, schema, required, disabled, errors, fieldProps,
  placeholder, autoComplete
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <Input
        type={isVisible ? 'text' : 'password'}
        icon={
          <Icon
            name={isVisible ? 'eye slash' : 'eye'}
            link
            onClick={() => setIsVisible((isVisible) => !isVisible)}
          />
        }
        autoComplete={autoComplete}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </Field>
  );
};

export default PasswordInput;
