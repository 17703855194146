import {Component} from 'react';
import PropTypes from 'prop-types';
import {Radio} from 'semantic-ui-react';
import cx from 'classnames';

import './StateToggle.less';

const optionPropType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
}).isRequired;

export default class StateToggle extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    leftOption: optionPropType,
    rightOption: optionPropType,
    onChange: PropTypes.func,
    style: PropTypes.any,
  };

  onChange = (event) => {
    const {value, leftOption, rightOption} = this.props;
    const newValue = value === leftOption.value ? rightOption.value : leftOption.value;
    this.props.onChange(newValue, event);
  };

  render() {
    const {style, className, value, leftOption, rightOption} = this.props;
    const {onChange} = this;
    return (
      <div className={cx('state-toggle', className)} style={style}>
        <div>{leftOption.text}</div>
        <Radio className='default-style' toggle checked={value === rightOption.value} onChange={onChange} />
        <div>{rightOption.text}</div>
      </div>
    );
  }
}
