import RCSlider from 'rc-slider';
import {isFunction} from 'lodash';

import './Slider.less';

const Slider = ({handleRender, ...sliderProps}) => {
  return (
    <RCSlider
      handleRender={
        (originalHandle, handleProps) =>
          <SliderHandle originalHandle={originalHandle} handleProps={handleProps} handleRender={handleRender} />
      }
      {...sliderProps}
    />
  );
};

const SliderHandle = ({originalHandle, handleProps, handleRender}) => {
  /**
   * NOTE: the following code is a workaround for the a11y issue:
   * under the hood 'rc-slider' makes its own calculations for the 'value' property and 'aria-valuenow' attribute.
   * When the initial 'value' is equal to 0 or is too small, the results of these calculations might be
   * in Exponential Notation (e.g. '-1e-15' when initial 'value' property === 0), which is okay for the 'value'
   * but inapropriate for the 'aria-valuenow' attribute
   */
  const ariaValueNow = (/.*e-.*/g.test(originalHandle.props['aria-valuenow']) && {'aria-valuenow': 0});

  return handleRender && isFunction(handleRender)
    ? (
      handleRender(
        {...originalHandle, props: {...originalHandle.props, ...ariaValueNow}},
        handleProps
      )
    )
    : (
      <div
        {...originalHandle.props}
        {...ariaValueNow}
      />
    );
};

export default Slider;
