import cx from 'classnames';
import React from 'react';

import {MenuItem} from './MainSidebarProvider';
import {useStateContext} from './StateContext';

import './Link.less';

type LinkProps = React.PropsWithChildren<{
  className?: string;
  highlighted?: boolean,
  menuItem: MenuItem,
}>;

export const Link: React.FC<LinkProps> = ({
  children,
  className,
  highlighted,
  menuItem
}) => {
  const {label, active, href, onClick, itemClass, target} = menuItem;
  const {closeMenu, size} = useStateContext();
  const isExpanded = size === 'expanded';

  const onClickHandler = React.useCallback(
    () => {
      onClick?.();
      if (!isExpanded) {
        closeMenu();
      }
    },
    [closeMenu, isExpanded, onClick]
  );

  const onKeyDown = React.useCallback((e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      onClickHandler?.();
    }
  }, [onClickHandler]);

  return (
    <a
      className={cx('link', highlighted, itemClass, {active}, className)}
      href={href}
      onClick={onClickHandler}
      onKeyDown={onKeyDown}
      tabIndex={0}
      target={target}
      aria-label={label}
    >
      {children}
    </a>
  );
};
