import {assign, compact, every, isArray, isBoolean, isNumber, isPlainObject, isString, map, omit} from 'lodash';
import {observer} from 'mobx-react';
import {useMemo} from 'react';

import RichFormFragment from './RichFormFragment';

import './SchemalessInput.less';

const NO_EXTENSION_WARNING = 'This property exists outside of the schema and that\'s why cannot be extended or ' +
  'validated';

const SchemalessInput = ({levelSchema, values, onChange, path, itemErrors, formState, setFormState}) => {
  const {schema} = useSchemalessInput({values, levelSchema});
  return (
    <RichFormFragment
      key='rich'
      schema={schema}
      values={values}
      onChange={onChange}
      path={path}
      errors={itemErrors}
      formState={formState}
      setFormState={setFormState}
      noSchema
    />
  );
};

export default observer(SchemalessInput);

const useSchemalessInput = ({values, levelSchema}) => {
  const schema = useMemo(() => {
    return map(omit(values, compact(map(levelSchema, 'name'))), getValueSchema);
  }, [levelSchema, values]);
  return {schema};
};

const getObjectSchema = (value) => {
  return map(value, getValueSchema);
};

const getArraySchema = (values) => {
  const allItems = assign({}, ...values);
  return getObjectSchema(allItems);
};

const getValueSchema = (value, name) => {
  let schema = {type: 'string'};
  if (isPlainObject(value)) {
    schema = {itemSchema: getObjectSchema(value)};
  } else if (isArray(value)) {
    schema = {
      type: 'array',
      ...(every(value, isPrimitive) ?
        {items: {anyOf: map(value, (item) => ({const: item, title: item}))}} :
        {itemSchema: getArraySchema(value)})
    };
  } else if (isNumber(value)) {
    schema = {type: 'number'};
  } else if (isBoolean(value)) {
    schema = {type: 'boolean'};
  }
  return {
    name,
    schema: {
      ...schema,
      description: NO_EXTENSION_WARNING
    },
    appearance: 'toggle',
    showLabelInline: true,
    fieldProps: {
      descriptionPosition: 'tooltip'
    }
  };
};

const isPrimitive = (value) => {
  return isString(value) || isNumber(value) || isBoolean(value);
};
