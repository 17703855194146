import cx from 'classnames';
import {filter, isNil, map, sortBy} from 'lodash';
import React from 'react';

import './MainSidebarMenu.less';
import {useMainSidebar} from './MainSidebarProvider';
import {useStateContext} from './StateContext';
import {TopSidebarItem} from './TopSidebarItem';

type MainSidebarMenuProps = {
  className?: string;
};

export const MainSidebarMenu: React.FC<MainSidebarMenuProps> = ({
  className,
}) => {
  const {menuItems: menuItemsRaw} = useMainSidebar();
  const {size} = useStateContext();
  const topLevelMenuItems = React.useMemo(
    () => sortBy(filter(menuItemsRaw, ({parent}) => isNil(parent)), 'order'),
    [menuItemsRaw]
  );
  return (
    <div className={cx('main-sidebar-menu', size, className)}>
      {map(
        topLevelMenuItems,
        (menuItem) => <TopSidebarItem
          key={menuItem.menuKey}
          menuItem={menuItem}
        />
      )}
    </div>
  );
};
