import {Component} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {debounce} from 'lodash';

import './FuzzySearchBox.less';

@observer
export default class FuzzySearchBox extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    debounceWait: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placeholder: 'Search...',
    initialValue: '',
    debounceWait: 100,
    disabled: false,
  };

  @observable value = this.props.initialValue;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  updateValue(value, debounce) {
    this.value = value;
    if (debounce) {
      this.debouncedOnChange();
    } else {
      this.onChange();
    }
  }

  onChange = () => {
    this.debouncedOnChange.cancel();
    this.props.onChange(this.value);
  };

  debouncedOnChange = debounce(this.onChange, this.props.debounceWait);

  onInputKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.updateValue('');
    } else if (e.key === 'Enter') {
      this.onChange();
    }
  };

  render() {
    const {placeholder, disabled, size} = this.props;
    return (
      <Input
        fluid
        className='fuzzy-search-box'
        size={size}
        icon={this.value.length ? {name: 'close', link: true, onClick: () => this.updateValue('')} : null}
        value={this.value}
        onChange={(e) => this.updateValue(e.target.value, true)}
        onKeyDown={this.onInputKeyDown}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }
}
