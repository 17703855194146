import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'semantic-ui-react';
import {observable, action, flow, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isEmpty, isFunction, isUndefined, isPlainObject, omit} from 'lodash';
import isPromise from 'is-promise';
import cx from 'classnames';

import Checkbox from './Checkbox';
import {notify} from '../notifier';
import {NetworkError} from '../request';
import {FormValidationError} from '../formSchema';
import {GlobalHelpButton} from './globalHelp';

const propsToOmit = ['processErrors', 'resetState', 'submit', 'notifyOnSuccess', 'successNotificationText', 'onSuccess',
  'onClose'];

@observer
export default class ResourceModal extends Component {
  static propTypes = {
    ...Modal.propTypes,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    header: PropTypes.string,
    mode: PropTypes.oneOf(['create', 'update', 'clone', 'view']),
    resourceName: PropTypes.string,
    resourceLabel: PropTypes.string,
    titlesByMode: PropTypes.object,
    actionsByMode: PropTypes.object,
    extraActions: PropTypes.node,
    showCreateAnother: PropTypes.bool,
    submitAvailable: PropTypes.bool,
    submit: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    processErrors: PropTypes.func,
    onSuccess: PropTypes.func,
    notifyOnSuccess: PropTypes.bool,
    successNotificationText: PropTypes.string,
    helpPageId: PropTypes.string
  };

  static defaultProps = {
    mode: 'create',
    resourceName: 'Resource',
    titlesByMode: {
      create: 'Create',
      update: 'Edit',
      clone: 'Clone',
      view: 'View'
    },
    actionsByMode: {
      create: 'Create',
      update: 'Update',
      clone: 'Create',
      view: 'Update'
    },
    notifyOnSuccess: true,
    showCreateAnother: true,
    submitAvailable: true,
    closeOnDimmerClick: false
  };

  @observable actionInProgress = false;
  @observable createAnother = false;
  @observable.shallow errors = [];
  @observable isModalOpen = false;

  @action
  setErrors = (errors) => {
    this.errors = errors;
  };

  @action
  openModal = () => {
    this.isModalOpen = true;
  };

  @action
  closeModal = () => {
    this.isModalOpen = false;
  };

  @action
  onClose = () => {
    if (!this.actionInProgress) {
      this.closeModal();
      if (this.props.onClose) this.props.onClose();
    }
  };

  @action
  onMount = () => {
    this.actionInProgress = false;
    this.createAnother = false;
    this.errors.length = 0;
    if (this.props.resetState) this.props.resetState();
  };

  @action
  toggleCreateAnother = () => {
    this.createAnother = !this.createAnother;
  };

  submitOnEnter = (e) => {
    if (e.key === 'Enter') this.submit();
  };

  @action.bound
  submit = flow(function*() {
    const {
      mode, resourceName, processErrors, resetState, submit, notifyOnSuccess, successNotificationText, onSuccess
    } = this.props;
    const {onClose, createAnother} = this;
    this.actionInProgress = true;
    this.errors.length = 0;
    let submitError = null;
    let result = null;
    try {
      result = submit();
      if (isPromise(result)) result = yield result;
    } catch (error) {
      submitError = error;
    }
    this.actionInProgress = false;
    if (!submitError) {
      if (createAnother) {
        resetState();
      } else {
        onClose();
      }
      if (onSuccess) {
        onSuccess({result, createAnother});
      }
      if (notifyOnSuccess) {
        const {resourceLabel, resourceHref, message: resultMessage} = isPlainObject(result) ? result : {};
        const message = resultMessage ||
          successNotificationText ||
          `${resourceName} has been successfully ${mode === 'update' ? 'updated' : 'created'}`;
        notify({
          message,
          resourceLabel, resourceHref
        });
      }
    } else {
      if (submitError instanceof FormValidationError) {
        this.errors = submitError.errors;
      } else if (submitError instanceof NetworkError) {
        const {response, responseBody} = submitError;
        if (response && [403, 422].includes(response.status) && responseBody && responseBody.errors) {
          this.errors = isFunction(processErrors) ? processErrors(responseBody) : responseBody;
        }
      }
      // FIXME(vkramskikh: generic errors also would appear here, need more intuitive processing
      if (!this.errors.length) this.errors = [{type: 'http', error: submitError}];
    }
  });

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      open, trigger, mode, header, resourceName, resourceLabel,
      titlesByMode, actionsByMode, scrolling,
      showCreateAnother, submitAvailable, extraActions,
      children, className, closeIcon, helpPageId, ...modalProps
    } = this.props;
    const isControlled = !isUndefined(open);
    const {actionInProgress, errors, setErrors} = this;
    const childProps = {actionInProgress, errors, setErrors};
    const showCloseIcon = isUndefined(closeIcon) ? !actionInProgress : closeIcon;
    return (
      <Modal
        className={cx('resource-modal', className)}
        closeIcon={showCloseIcon}
        onKeyDown={this.submitOnEnter}
        trigger={isControlled ? trigger : React.cloneElement(trigger, {onClick: this.openModal})}
        open={isControlled ? open : this.isModalOpen}
        onClose={this.onClose}
        onMount={this.onMount}
        {...omit(modalProps, propsToOmit)}
      >
        <Modal.Header>
          {helpPageId && <GlobalHelpButton helpPageId={helpPageId} />}
          {isEmpty(header) ?
            [titlesByMode[mode], ' ', resourceName, resourceLabel && mode === 'update' && [': ', resourceLabel]]
          :
            header
          }
        </Modal.Header>
        <Modal.Content scrolling={!!scrolling}>
          {isFunction(children) ? children(childProps) : React.cloneElement(children, childProps)}
        </Modal.Content>
        <Modal.Actions>
          {showCreateAnother && mode === 'create' &&
            <Checkbox
              label='Create Another?'
              checked={this.createAnother}
              onChange={this.toggleCreateAnother}
              disabled={actionInProgress}
            />
          }
          {extraActions}
          <Button
            primary
            size='large'
            content={actionsByMode[mode]}
            disabled={!submitAvailable || actionInProgress}
            loading={actionInProgress}
            onClick={this.submit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
