import {map} from 'lodash';
import {FC, Fragment, useMemo} from 'react';
import {Breadcrumb, BreadcrumbDivider, BreadcrumbSection} from 'semantic-ui-react';

import {useGlobalHelp} from './GlobalHelpContext';
import {HelpPageType, HelpSectionType, isHelpPageType} from './types';

import './PageBreadcrumbs.less';

function findPathByUrl(
  url: string,
  sections: (HelpSectionType | HelpPageType)[]
): HelpSectionType[] | null {
  for (const section of sections) {
    if (isHelpPageType(section)) {
      if (section.url === url) {
        return [];
      }
    } else {
      const childPath = findPathByUrl(url, section.children);
      if (childPath) {
        return [section, ...childPath];
      }
    }
  }
  return null;
}

export const PageBreadcrumbs: FC = () => {
  const {topics, url} = useGlobalHelp();
  const sections = useMemo(() => (url && topics) ? findPathByUrl(url, topics) : [], [url, topics]);
  return (
    <Breadcrumb
      className='global-help-breadcrumb'
      size='mini'
    >
      {map(sections, (section) => (
        <Fragment key={section.title}>
          <BreadcrumbSection>{section.title}</BreadcrumbSection>
          <BreadcrumbDivider />
        </Fragment>
      ))}
    </Breadcrumb>
  );
};
