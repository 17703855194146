import {Component, cloneElement} from 'react';
import {scaleLinear, scaleTime} from 'd3';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Grid} from '@visx/grid';
import {Group} from '@visx/group';
import {Zoom} from '@visx/zoom';
import {RectClipPath} from '@visx/clip-path';
import {constant, min, clamp, isFunction, isNumber, compact, uniqueId} from 'lodash';
import cx from 'classnames';

import {formatChartAxisTime, formatNumber} from '../../formatters';

import './NumericChartLayout.less';
import {createAxisMeasurer} from './useAxisWidth';

@observer
export default class NumericChartLayout extends Component {
  static defaultProps = {
    mode: 'compact',
    minValue: 0,
    maxValue: 0,
    units: '',
    dimensions: {
      compact: {
        height: 60,
        margin: {top: 10, right: 10, bottom: 10, left: 0},
        numTicksRows: 3
      },
      expanded: {
        height: 300,
        margin: {top: 10, right: 10, bottom: 0, left: 0},
        numTicksRows: 10
      },
    },
    maxSamplesDisplayCircles: 150,
    useTimestampZoom: false,
    showTimelineWithMilliseconds: false,
  };

  defaultZoomTransformMatrix = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0,
  };

  xScale = scaleTime();
  yScale = scaleLinear();

  zoomClipAreaId = uniqueId('zoom-clip-area-');

  @observable
  leftAxisWidth = 0;

  @action
  measureLeftAxis = (width) => {
    if (isNumber(width) && this.leftAxisWidth !== width) this.leftAxisWidth = width;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get yAxisLabel() {
    const {units, yLabel} = this.props;
    return compact([yLabel, units]).join(', ');
  }

  formatYTick = (value) => {
    const {units} = this.props;
    return formatNumber(value, {units, short: true, withIndent: true});
  };

  rescaleX = (scale, zoom) => {
    const newXAxisDomain = scale
      .range()
      .map((range) => scale.invert((range - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX));
    return scale.copy().domain(newXAxisDomain);
  };

  render() {
    const {
      xScale, yScale, leftAxisWidth, formatYTick, yAxisLabel, measureLeftAxis,
      zoomClipAreaId, defaultZoomTransformMatrix, rescaleX,
      props: {
        children, mode, dimensions, className, minValue, maxValue, width: chartWidth,
        timelineStartTime, timelineEndTime, xLabel, useTimestampZoom, showTimelineWithMilliseconds,
      }
    } = this;

    // In compact mode no X-Axis ticks or label must be displayed to consume space
    const isCompact = mode === 'compact';

    const {height: chartHeight, margin, numTicksRows: chartNumTicksRows} = dimensions[mode];

    const marginLeft = margin.left + leftAxisWidth + (yAxisLabel ? 20 : 0);
    const marginBottom = margin.bottom + (isCompact ? 0 : (xLabel ? 50 : 25));

    const xMax = chartWidth - marginLeft - margin.right;
    const yMax = chartHeight - margin.top - marginBottom;

    xScale.domain([timelineStartTime, timelineEndTime]).rangeRound([0, xMax]);
    yScale.domain([minValue, maxValue]).rangeRound([yMax, 0]);
    const yScaleMin = minValue > 0 ? yScale(minValue) : yScale(min([minValue, 0]));

    const showTicks = chartWidth > 0;
    const numTicksRows = showTicks ? clamp(Math.abs(maxValue) + Math.abs(minValue), 1, chartNumTicksRows) : 0;
    const numTicksColumns = (showTicks && !isCompact) ? this.props.numTicksColumns : 0;
    const chartWidthMs = Date.parse(timelineEndTime) - Date.parse(timelineStartTime);

    return (
      <Zoom
        width={chartWidth}
        height={chartHeight}
        scaleXMin={defaultZoomTransformMatrix.scaleX}
        scaleYMin={defaultZoomTransformMatrix.scaleY}
        constrain={(nextTrMatrix, prevTrMatrix) => {
          if (!useTimestampZoom) {
            return defaultZoomTransformMatrix;
          }
          return chartWidthMs / nextTrMatrix.scaleX < 5 || nextTrMatrix.scaleX < 1
            ? prevTrMatrix
            : nextTrMatrix;
        }}
      >
        {(zoom) => {
          const xScaleWithZoom = rescaleX(xScale, zoom);
          const updatedXScale = useTimestampZoom ? xScaleWithZoom : xScale;
          const childrenProps = {
            xScale: updatedXScale,
            yScale, yScaleMin, yMax, xMax,
          };
          return (
            <svg
              {...useTimestampZoom && {ref: zoom.containerRef}}
              width={chartWidth}
              height={chartHeight}
              className={cx('numeric-chart-layout', className)}
            >
              <Group top={margin.top} left={marginLeft}>
                <Group clipPath={`url(#${zoomClipAreaId})`}>
                  {isFunction(children) ? children(childrenProps) : cloneElement(children, childrenProps)}
                </Group>
                <AxisBottom
                  axisClassName='timeline-axis axis-bottom'
                  top={yScaleMin}
                  scale={updatedXScale}
                  label={isCompact ? undefined : xLabel}
                  numTicks={numTicksColumns}
                  tickFormat={(date) => formatChartAxisTime(date, showTimelineWithMilliseconds)}
                  tickLabelProps={constant({})}
                />
                <AxisLeft
                  innerRef={createAxisMeasurer(measureLeftAxis)}
                  axisClassName='timeline-axis axis-left'
                  labelOffset={leftAxisWidth}
                  scale={yScale}
                  numTicks={numTicksRows}
                  label={yAxisLabel}
                  tickFormat={formatYTick}
                />
              </Group>
              <RectClipPath
                id={zoomClipAreaId}
                x={0}
                y={-margin.top}
                width={chartWidth}
                height={chartHeight + margin.top}
              />
              {showTicks &&
                <Grid
                  className='timeline-grid'
                  top={margin.top}
                  left={marginLeft}
                  width={xMax}
                  height={yMax}
                  xScale={updatedXScale}
                  yScale={yScale}
                  numTicksRows={numTicksRows}
                  numTicksColumns={numTicksColumns}
                  stroke={null}
                />
              }
            </svg>
          );
        }}
      </Zoom>
    );
  }
}
