export const brandStandardColorNames = [
  'red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey',
];

export const brandCustomColorNames = [
  'light-red', 'light-orange', 'light-yellow', 'light-olive', 'light-green', 'light-teal', 'light-blue',
  'light-violet', 'light-purple', 'light-pink', 'light-brown', 'light-grey',
  'dark-red', 'dark-orange', 'dark-yellow', 'dark-olive', 'dark-green', 'dark-teal', 'dark-blue', 'dark-violet',
  'dark-purple', 'dark-pink', 'dark-brown', 'dark-grey',
];

export const brandColorNames = [...brandStandardColorNames, ...brandCustomColorNames];
