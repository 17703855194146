import {format} from 'd3';

const shortFormatter = format('.3s');
const longFormatter = format(',');

export default function formatNumber(value, {units = '', short = false, withIndent = false} = {}) {
  value = (short ? shortFormatter : longFormatter)(value);
  value = value.replace(/(\.\d*[1-9])0*|(\.0*)/, '$1');
  if (withIndent) {
    value = value.replace(/(\d+)(?!.*\d)/, '$1 ');
  }
  value += units;
  return value;
}
