import {indexOf} from 'lodash';
import {FC, useMemo} from 'react';
import {Button} from 'semantic-ui-react';

import {useGlobalHelp} from './GlobalHelpContext';

import './NextPreviousNavigation.less';

export const NextPreviousNavigation: FC = () => {
  const {navigate, orderedTopicUrls, url} = useGlobalHelp();
  const {prevUrl, nextUrl} = useMemo(() => {
    const index = indexOf(orderedTopicUrls, url!);
    return (index === -1) ? {prevUrl: null, nextUrl: null} : {
      prevUrl: index > 0 ? orderedTopicUrls[index - 1] : null,
      nextUrl: index < orderedTopicUrls.length - 1 ? orderedTopicUrls[index + 1] : null
    };
  }, [orderedTopicUrls, url]);

  if (!prevUrl && !nextUrl) {
    return null;
  }

  return (
    <div className='global-help-navigation-panel'>
      <Button
        basic
        content='Previous Topic'
        disabled={!prevUrl}
        icon='angle left'
        labelPosition='left'
        onClick={() => navigate(prevUrl)}
      />
      <Button
        basic
        content='Next Topic'
        disabled={!nextUrl}
        icon='angle right'
        labelPosition='right'
        onClick={() => navigate(nextUrl)}
      />
    </div>
  );
};
