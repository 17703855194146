import PropTypes from 'prop-types';
import {Menu, Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {onEnterKeyHandler} from '../keyHandlers';
import PermissionChecker from './PermissionChecker';

const ActionsMenu = ({items, disabled: menuDisabled, ...props}) => {
  return (
    <Menu compact icon {...props}>
      {items.map((
        {
          key, icon, content, title, disabled, href, onClick, hasPermissions = true,
          notPermittedMessage, popupPosition = 'bottom right', active
        },
        index
      ) => {
        key = key ?? index;
        disabled = menuDisabled || disabled;
        const itemProps = {key, icon, disabled, link: true, content, active};
        const label = title ? {'aria-label': title} : {};
        if (href && !disabled && hasPermissions) {
          Object.assign(itemProps, label, {
            as: Link,
            to: href
          });
        } else if (onClick) {
          Object.assign(itemProps, label, {
            onClick: onClick,
            onKeyDown: onEnterKeyHandler(onClick),
            role: 'button',
            tabIndex: 0,
            'aria-disabled': disabled
          });
        }
        const menuItemMarkup = <Menu.Item {...itemProps} />;
        return !hasPermissions
          ? (
            <PermissionChecker
              key={key}
              hasPermissions={hasPermissions}
              notPermittedMessage={notPermittedMessage}
              wrapperClassName='actions-menu-item'
              popupProps={{
                position: popupPosition
              }}
            >
              {menuItemMarkup}
            </PermissionChecker>
          )
          : title
            ? <Popup key={key} trigger={menuItemMarkup} content={title} position={popupPosition} />
            : menuItemMarkup;
      })}
    </Menu>
  );
};

ActionsMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    hasPermissions: PropTypes.bool,
    notPermittedMessage: PropTypes.string,
    active: PropTypes.bool
  })).isRequired,
};

export default ActionsMenu;
