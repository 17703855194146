import {isNil} from 'lodash';
import React from 'react';

import './PopupMenu.less';
import {useStateContext} from './StateContext';

export const PopupMenu: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [top, setTop] = React.useState<number | null>(null);
  const [originalTop, setOriginalTop] = React.useState<number | null>(null);
  const {height} = useStateContext();

  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const updateTop = () => {
      const dropdownRect = ref.current!.getBoundingClientRect();
      if (dropdownRect.height >= height!) {
        setTop(0);
      } else {
        const spaceAtTheBottom = height! - originalTop! - dropdownRect.height;
        setTop(originalTop! + (spaceAtTheBottom < 0 ? spaceAtTheBottom : 0));
      }
    };

    const divTop = ref.current?.parentElement?.getBoundingClientRect().top;
    if (ref.current && !isNil(divTop)) {
      setOriginalTop(divTop);
      updateTop();
    }
  }, [ref, originalTop, height]);

  return (
    <div
      ref={ref}
      className='popup-content'
      style={isNil(top) ? undefined : {top}}
    >
      {children}
    </div>
  );
};
