import {Component, Fragment, createRef} from 'react';
import {action, makeObservable, reaction} from 'mobx';
import {isNil} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';

import './GenericChartHoverHandler.less';

@observer
export default class GenericChartHoverHandler extends Component {
  static defaultProps = {
    popupAnchorWidth: 35,
  };

  popupAnchorRef = createRef();
  graphRectRef = createRef();
  highlightSampleAnimationFrameId = null;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.disposeValuesReaction = reaction(
      () => this.props.values,
      () => {
        if (!isNil(this.props?.sampleX)) {
          this.highlightByPositionAndHoveredNode(this.props.sampleX, this.props.hoveredNode);
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeValuesReaction();
  }

  @action
  reset = () => {
    this.cancelHighlightSampleAnimationFrameId();
    this.props?.onReset();
  };

  onMouseMove = (e) => {
    const rect = this.graphRectRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    this.highlightByPositionAndHoveredNode(x, e.target);
  };

  highlightByPositionAndHoveredNode = (x, hoveredNode) => {
    this.cancelHighlightSampleAnimationFrameId();
    this.highlightSampleAnimationFrameId =
      requestAnimationFrame(
        this.props.getHandlerFn?.({x, hoveredNode, reset: this.reset, popupAnchorRef: this.popupAnchorRef})
      );
  };

  cancelHighlightSampleAnimationFrameId = () => {
    if (this.highlightSampleAnimationFrameId) {
      // eslint-disable-next-line no-undef
      cancelAnimationFrame(this.highlightSampleAnimationFrameId);
    }
  };

  render() {
    const {
      popupAnchorRef, graphRectRef, onMouseMove, reset,
      props: {width, height, sampleX, lineClassName, lineHidden, popupAnchorWidth, children}
    } = this;

    return (
      <Fragment>
        <g
          onMouseMove={onMouseMove}
          onMouseLeave={reset}
        >
          <rect
            key='rect'
            ref={graphRectRef}
            x={0}
            y={0}
            width={width}
            height={height}
            fill='none'
            pointerEvents='visible'
          />
          {children}
        </g>
        <line
          key='line'
          className={cx(
            'generic-chart-hover-handler-line',
            lineClassName,
            {hidden: lineHidden})
          }
          y1={0}
          y2={height}
          x1={sampleX}
          x2={sampleX}
        />
        <rect
          key='popup-anchor'
          ref={popupAnchorRef}
          className='popup-anchor'
          x={(sampleX - popupAnchorWidth / 2) || 0}
          y={0}
          width={popupAnchorWidth}
          height={height}
        />
      </Fragment>
    );
  }
}
