import {createContext} from 'react';

const LayoutContext = createContext();

const LayoutContextProvider = ({children, ...props}) => {
  return <LayoutContext.Provider value={props}>{children}</LayoutContext.Provider>;
};

export {LayoutContextProvider};
export default LayoutContext;
