import PropTypes from 'prop-types';
import {Modal} from 'semantic-ui-react';
import {isEmpty, intersection} from 'lodash';

Modal.propTypes = {
  ...Modal.propTypes,
  autoFocus: PropTypes.bool
};

Modal.defaultProps = {
  ...Modal.defaultProps,
  autoFocus: true
};

const originalComponentDidMount = Modal.prototype.componentDidMount;

Modal.prototype.componentDidMount = function() {
  originalComponentDidMount?.();
  if (this.props.open && this.props.autoFocus) {
    focusFirstFocusableElement(this.ref?.current);
  }
};

Modal.prototype.componentDidUpdate = function(prevProps, prevState) {
  if (this.props.autoFocus) {
    if (!prevState.open && this.state.open) {
      focusFirstFocusableElement(this.ref?.current);
    }
  }
};

const focusableElementSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

function getNodeToFocus(node, focusableElements) {
  let nodeToFocus = isEmpty(focusableElements) ? node : focusableElements[0];
  if (nodeToFocus !== node) {
    const isDropdown = intersection(nodeToFocus.parentNode.classList, ['ui', 'dropdown']).length === 2 ||
      intersection(nodeToFocus.classList, ['ui', 'dropdown']).length === 2;
    if (isDropdown) {
      nodeToFocus = node;
    }
  }
  return nodeToFocus;
}

function focusFirstFocusableElement(node) {
  if (!node || node.contains?.(document.activeElement)) {
    return;
  }
  const focusableElements = node?.querySelectorAll(focusableElementSelector);
  const nodeToFocus = getNodeToFocus(node, focusableElements);
  if (nodeToFocus === node) {
    nodeToFocus.setAttribute('tabindex', 0);
  }
  nodeToFocus?.focus();
}
