import {Field} from '../../components';

import CodeEditorControl from './CodeEditorControl';

export default function CodeEditorInput({name, schema, required, disabled, errors, fieldProps, ...controlProps}) {
  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <CodeEditorControl {...controlProps} />
    </Field>
  );
}
