import ace from 'ace-builds';

ace.define(
  'ace/base_completer',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {Autocomplete} = require('ace/autocomplete');
    const textCompleter = require('ace/autocomplete/text_completer');

    class BaseCompleter {
      getTextCompletions(state, session, pos, prefix) {
        return new Promise((resolve, reject) => {
          textCompleter.getCompletions(state, session, pos, prefix, (err, result) =>
            err ? reject(err) : resolve(result)
          );
        });
      }

      getCustomCompletions(state, session, pos, prefix) {
        return this.getTextCompletions(state, session, pos, prefix);
      }

      async getCompletions(state, session, pos, prefix, cb) {
        try {
          const completions = await this.getCustomCompletions(state, session, pos, prefix);
          cb(null, completions);
        } catch (err) {
          cb(err);
          throw err;
        }
      }

      showCompletionsPopup(editor) {
        if (!editor.completer) editor.completer = new Autocomplete();
        if (editor.completer.autoSelect) editor.completer.autoSelect = false;
        editor.completer.showPopup(editor);
      }

      hideCompletionsPopup(editor) {
        if (!editor.completer) return;
        editor.completer.detach();
      }
    }

    exports.BaseCompleter = BaseCompleter;
  }
);

ace.require(['ace/base_completer']);
