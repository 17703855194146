import {Popup} from 'semantic-ui-react';

import formatNumber from '../formatters/formatNumber';

export default function FormattedNumber({value, units}) {
  const shortValue = formatNumber(value, {units, short: true});
  const longValue = formatNumber(value, {units, short: false});
  return shortValue === longValue ?
    <span>{shortValue}</span>
  :
    <Popup
      trigger={<span>{shortValue}</span>}
      content={longValue}
    />;
}
