import {Component} from 'react';
import PropTypes from 'prop-types';

import GenericErrors from './GenericErrors';

export default class FetchDataError extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(Error)
  };

  render() {
    const {error, children, ...toMessageParams} = this.props;
    let title, content;
    if (error.toMessage) ({title, content} = error.toMessage(toMessageParams));
    if (!title) title = 'Error';
    if (!content) content = String(error);
    return <GenericErrors header={title} errors={content}>{children}</GenericErrors>;
  }
}
