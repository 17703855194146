import {scaleLinear} from 'd3';
import {Component} from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {AreaStack} from '@visx/shape';
import {curveLinear} from '@visx/curve';
import {forEach, map, set, range, orderBy, isNil, flatMap, transform} from 'lodash';
import cx from 'classnames';

import parseTimestamp from '../../parseTimestamp';
import MultipleChartContainer from './MultipleChartContainer';
import {COMBINE_GRAPHS_MODE} from './consts';

import './StackedChart.less';

const getZeroIntersections = (domain, values) =>
  transform(domain, (acc, _, i) => {
    if (i === 0) return;
    const [x1, x2] = [domain[i - 1], domain[i]];
    const [y1, y2] = [values[i - 1], values[i]];
    if (Math.sign(y1) * Math.sign(y2) < 0) {
      const m = (y2 - y1) / (x2 - x1);
      const b = m * x1 - y1;
      acc.push(b / m);
    }
  }, []);

@observer
export default class StackedChart extends Component {
  static defaultProps = {
    itemPropertiesPath: 'properties',
  };

  @observable hoveredNodeIndex = null;

  nodeRefs = new WeakMap();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get stacked() {
    const {items, itemSamplesPath, itemSamplesTimes, valueKeyName} = this.props;
    const stacked = {};
    forEach(items, (item, itemIndex) => {
      const samples = item[itemSamplesPath];
      forEach(samples, (sample, sampleIndex) => {
        const sampleTime = +itemSamplesTimes[itemIndex][sampleIndex];
        set(stacked, [sampleTime, itemIndex], sample[valueKeyName]);
      });
    });
    return stacked;
  }

  @computed get rangeLimits() {
    let maxValue = 0;
    let minValue = 0;
    forEach(this.stacked, (values) => {
      let sumPositiveValues = 0;
      let sumNegativeValues = 0;
      forEach(values, (value) => {
        if (value > 0) sumPositiveValues += value;
        if (value < 0) sumNegativeValues += value;
      });
      if (maxValue < sumPositiveValues) maxValue = sumPositiveValues;
      if (minValue > sumNegativeValues) minValue = sumNegativeValues;
    });
    return {maxValue, minValue};
  }

  @action
  highlightChartLine = (line) => {
    if (!isNil(line)) this.hoveredNodeIndex = line;
  };

  @action
  resetHighlightChartLine = () => {
    if (!isNil(this.hoveredNodeIndex)) this.hoveredNodeIndex = null;
  };

  updateRefs = (ref, nodeIndex) => {
    if (ref) this.nodeRefs.set(ref, nodeIndex);
  };

  render() {
    const {
      rangeLimits: {minValue, maxValue}, hoveredNodeIndex,
      props: {
        mode, dimensions,
        items, itemSamplesPath, sampleTimes, itemSamplesTimes,
        width: chartWidth,
        showPopup, hidePopup,
        timelineStartTime, timelineEndTime, useTimestampZoom,
        timeIndicators, maxSamplesDisplayCircles, selectedTimestamp, showTimelineWithMilliseconds,
        valueKeyName, units, yAxisLeftLabelWidth, popupContentItemKeys,
        inlineUnitsMaxLength, numTicksColumns, popupRenderers, itemPropertiesPath,
        itemColors, xLabel, yLabel, popupContentCellRenderFn, onSelectTimestamp,
      }
    } = this;
    const childProps = {
      mode, dimensions, numTicksColumns,
      items, itemSamplesPath, itemSamplesTimes,
      minValue, maxValue,
      width: chartWidth,
      showPopup, hidePopup, popupContentItemKeys,
      timelineStartTime, timelineEndTime, useTimestampZoom,
      timeIndicators, maxSamplesDisplayCircles, selectedTimestamp, showTimelineWithMilliseconds,
      valueKeyName, units, yAxisLeftLabelWidth, popupRenderers, itemPropertiesPath,
      inlineUnitsMaxLength, sampleTimes, combiningGraphsMode: COMBINE_GRAPHS_MODE.STACKED,
      nodeRefs: this.nodeRefs, highlightChartLine: this.highlightChartLine,
      resetHighlightChartLine: this.resetHighlightChartLine, updateRefs: this.updateRefs,
      itemColors, xLabel, yLabel, popupContentCellRenderFn, onSelectTimestamp,
    };

    return (
      <MultipleChartContainer
        className='stacked-chart'
        {...childProps}
      >
        {({xScale, yScale, itemColors}) => {
          const castChartData = (stack) => {
            return orderBy(map(stack, (values, x) => ({...values, x: +x})), 'x');
          };
          const negativeStack = {};
          const positiveStack = {};
          if (chartWidth > 0) {
            const zeroIntersections = [];
            const interpolatedItems = map(items, (item) => {
              const samples = item[itemSamplesPath];
              const domain = map(samples, ({timestamp}) => parseTimestamp(timestamp));
              const values = map(samples, valueKeyName);
              zeroIntersections.push(...getZeroIntersections(domain, values));
              return scaleLinear(domain, values);
            });
            const timestamps = flatMap(items, (item) => map(
              item[itemSamplesPath],
              ({timestamp}) => parseTimestamp(timestamp)
            ));
            forEach([...timestamps, ...zeroIntersections], (timestamp) => {
              const x = xScale(timestamp);
              if (!positiveStack[x]) {
                positiveStack[x] = [];
                negativeStack[x] = [];
                forEach(interpolatedItems, (interpolatedItem, itemIndex) => {
                  const value = interpolatedItem(timestamp);
                  positiveStack[x][itemIndex] = value >= 0 ? value : 0;
                  negativeStack[x][itemIndex] = value >= 0 ? 0 : value;
                });
              }
            });
          }
          const positiveData = castChartData(positiveStack);
          const negativeData = castChartData(negativeStack);
          const keys = range(items.length);
          const childProps = {keys, yScale, itemColors, hoveredNodeIndex, updateRefs: this.updateRefs};
          return [
            <StackedChartChild key='positive' data={positiveData} {...childProps} />,
            <StackedChartChild key='negative' data={negativeData} {...childProps} />
          ];
        }}
      </MultipleChartContainer>
    );
  }
}

const StackedChartChild = ({data, keys, yScale, itemColors, hoveredNodeIndex, updateRefs}) => (
  <AreaStack
    data={data}
    keys={keys}
    curve={curveLinear}
    x={(d) => d.data.x ?? 0}
    y0={(d) => yScale(d[0]) ?? 0}
    y1={(d) => yScale(d[1]) ?? 0}
  >
    {({stacks, path}) =>
      stacks.map((stack, index) => {
        return (
          <path
            key={`stack-${stack.key}`}
            ref={(ref) => updateRefs(ref, index)}
            className={cx(
              'stacked-chart-line',
              itemColors[index],
              {shadow: !isNil(hoveredNodeIndex) && index !== hoveredNodeIndex}
            )}
            d={path(stack) || ''}
            stroke='transparent'
          />
        );
      })
    }
  </AreaStack>
);
