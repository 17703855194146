import {forEach} from 'lodash';
// workaround for https://github.com/Semantic-Org/Semantic-UI-React/issues/4488
// the attributes provided by the built-in 'hide' modifier aren't added to the wrapper div,
// this modifier actually adds them to the popup's wrapper div.

const hideModifierAttributes = ['data-popper-escaped', 'data-popper-reference-hidden'];

const syncPopperHideAttributes = {
  name: 'syncPopperHideAttributes',
  enabled: true,
  phase: 'write',
  fn: ({state: {elements: {popper: popperElement}, attributes: {popper: attributes}}}) => {
    forEach(hideModifierAttributes, (attribute) => {
      if (attributes[attribute]) {
        popperElement.setAttribute(attribute, '');
      } else {
        popperElement.removeAttribute(attribute);
      }
    });
  }
};

export default syncPopperHideAttributes;
